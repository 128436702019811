/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { ClaimCodeTable_ClaimCodeFragmentDoc } from '../../../../../../components/displays/ClaimCodeTable/__generated__/ClaimCodeTable.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type ClaimCodePageQueryVariables = Types.Exact<{
  customerHandle: Types.Scalars['String'];
  pageNumber?: Types.InputMaybe<Types.Scalars['Int']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']>;
  searchTerm?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.MultiClaimFilters>;
}>;


export type ClaimCodePageQuery = { __typename: 'Query', customer?: { __typename: 'Customer', id: string, totalFilteredMultiClaimCount: number, groups: Array<{ __typename: 'Group', id: string, name: string }>, sites: Array<{ __typename: 'Site', name: string, id: string }>, multiClaimCodes: Array<{ __typename: 'MultiClaimCodeDocument', createdAt: any, customerId: string, organizationId: string, state?: Types.MultiClaimCodeStatus | null, usageCount?: number | null, id: string, code: string, name?: string | null, expiry?: any | null, groups?: Array<string> | null, site?: string | null, prefix?: string | null }> } | null };


export const ClaimCodePageDocument = gql`
    query ClaimCodePage($customerHandle: String!, $pageNumber: Int, $pageSize: Int, $searchTerm: String, $filter: MultiClaimFilters) {
  customer: customerByHandle(handle: $customerHandle) {
    id
    groups {
      id
      name
    }
    sites {
      name
      id
    }
    multiClaimCodes(
      pageNumber: $pageNumber
      pageSize: $pageSize
      searchTerm: $searchTerm
      filter: $filter
    ) {
      ...ClaimCodeTable_claimCode
      createdAt
      customerId
      organizationId
      state
      usageCount
    }
    totalFilteredMultiClaimCount(searchTerm: $searchTerm, filter: $filter)
  }
}
    ${ClaimCodeTable_ClaimCodeFragmentDoc}`;

/**
 * __useClaimCodePageQuery__
 *
 * To run a query within a React component, call `useClaimCodePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimCodePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimCodePageQuery({
 *   variables: {
 *      customerHandle: // value for 'customerHandle'
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      searchTerm: // value for 'searchTerm'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useClaimCodePageQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ClaimCodePageQuery, ClaimCodePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ClaimCodePageQuery, ClaimCodePageQueryVariables>(ClaimCodePageDocument, options);
      }
export function useClaimCodePageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimCodePageQuery, ClaimCodePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ClaimCodePageQuery, ClaimCodePageQueryVariables>(ClaimCodePageDocument, options);
        }
export type ClaimCodePageQueryHookResult = ReturnType<typeof useClaimCodePageQuery>;
export type ClaimCodePageLazyQueryHookResult = ReturnType<typeof useClaimCodePageLazyQuery>;
export type ClaimCodePageQueryResult = ApolloReactCommon.QueryResult<ClaimCodePageQuery, ClaimCodePageQueryVariables>;