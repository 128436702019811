import { gql } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { ClaimCodeTable_ClaimCodeFragment } from '../__generated__/ClaimCodeTable.graphql';

export function ExpiryOnCell({
  value,
}: PropsWithChildren<CellProps<ClaimCodeTable_ClaimCodeFragment, string>>) {
  const date = new Date(value);
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  }).format(date);

  return <Box>{formattedDate}</Box>;
}

ExpiryOnCell.graphql = {
  fragments: {
    ExpiryOnCell_claimCode: gql`
      fragment ExpiryOnCell_claimCode on MultiClaimCodeDocument {
        id
        expiry
      }
    `,
  },
};
