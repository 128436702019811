import { createIcon } from '@chakra-ui/react';

export const SingleDisplayIllustration = createIcon({
  displayName: 'SingleDisplayIllustration',
  viewBox: '0 0 173 173',
  defaultProps: {
    fill: 'none',
    width: '173px',
    height: '173px',
  },
  path: (
    <>
      <circle cx="86.5" cy="86.5" r="86.5" fill="#D9E2EC" />
      <g filter="url(#filter0_dd_10_3347)">
        <path
          d="M36.5225 61.5107C36.5225 58.6797 38.8174 56.3848 41.6484 56.3848H131.352C134.183 56.3848 136.478 58.6797 136.478 61.5107V114.051C136.478 116.882 134.183 119.177 131.352 119.177H41.6484C38.8174 119.177 36.5225 116.882 36.5225 114.051V61.5107Z"
          fill="white"
        />
        <path
          d="M62.1521 119.177H112.771V120.459C112.771 121.874 111.623 123.022 110.208 123.022H64.7151C63.2996 123.022 62.1521 121.874 62.1521 120.459V119.177Z"
          fill="white"
        />
        <path
          d="M62.1521 117.896H60.8706H41.6484C39.5252 117.896 37.8039 116.175 37.8039 114.051V61.5107C37.8039 59.3875 39.5252 57.6662 41.6484 57.6662H131.352C133.475 57.6662 135.197 59.3875 135.197 61.5107V114.051C135.197 116.175 133.475 117.896 131.352 117.896H114.052H112.771H62.1521ZM63.4336 120.459H111.489C111.489 121.167 110.915 121.74 110.208 121.74H64.7151C64.0073 121.74 63.4336 121.167 63.4336 120.459Z"
          stroke="#0F5291"
          strokeWidth="2.56296"
        />
      </g>
      <path
        d="M53.0379 93.9342C52.4627 93.9305 51.9075 93.834 51.3723 93.6447C50.8408 93.4554 50.3639 93.1478 49.9416 92.7218C49.5193 92.2959 49.1844 91.7316 48.9368 91.029C48.6929 90.3263 48.5709 89.4635 48.5709 88.4405C48.5746 87.5013 48.682 86.6621 48.8931 85.9231C49.1079 85.1804 49.4137 84.5506 49.8105 84.0336C50.211 83.5167 50.6897 83.1235 51.2467 82.8541C51.8038 82.581 52.4281 82.4445 53.1198 82.4445C53.8661 82.4445 54.5251 82.5901 55.0967 82.8814C55.6682 83.169 56.1269 83.5603 56.4728 84.0555C56.8223 84.5506 57.0334 85.1058 57.1062 85.721H54.7745C54.6834 85.3315 54.4923 85.0257 54.2011 84.8036C53.9098 84.5815 53.5494 84.4705 53.1198 84.4705C52.3917 84.4705 51.8383 84.7872 51.4597 85.4207C51.0847 86.0541 50.8936 86.9151 50.8863 88.0037H50.9628C51.1302 87.6724 51.356 87.3902 51.6399 87.1572C51.9275 86.9206 52.2534 86.7404 52.6174 86.6166C52.9851 86.4892 53.3728 86.4255 53.7806 86.4255C54.4432 86.4255 55.0329 86.582 55.5499 86.8951C56.0669 87.2046 56.4746 87.6305 56.7731 88.1729C57.0717 88.7154 57.2209 89.3361 57.2209 90.0351C57.2209 90.7923 57.0444 91.4658 56.6912 92.0556C56.3417 92.6454 55.8521 93.1077 55.2223 93.4427C54.5961 93.774 53.868 93.9378 53.0379 93.9342ZM53.027 92.0775C53.391 92.0775 53.7169 91.9901 54.0045 91.8153C54.2921 91.6406 54.5178 91.404 54.6816 91.1054C54.8455 90.8069 54.9274 90.472 54.9274 90.1006C54.9274 89.7293 54.8455 89.3962 54.6816 89.1013C54.5214 88.8064 54.2994 88.5716 54.0154 88.3968C53.7314 88.2221 53.4074 88.1347 53.0434 88.1347C52.7703 88.1347 52.5173 88.1857 52.2843 88.2876C52.055 88.3896 51.8529 88.5315 51.6782 88.7136C51.507 88.8956 51.3723 89.1068 51.2741 89.347C51.1758 89.5837 51.1266 89.8367 51.1266 90.1061C51.1266 90.4665 51.2085 90.796 51.3723 91.0945C51.5398 91.393 51.7655 91.6315 52.0495 91.8099C52.3371 91.9883 52.6629 92.0775 53.027 92.0775Z"
        fill="#0F5291"
      />
      <rect
        x="47.0948"
        y="79.1309"
        width="10.8926"
        height="17.3"
        rx="0.961111"
        stroke="#9FB3C8"
        strokeWidth="0.640741"
      />
      <path
        d="M65.7025 93.934C64.887 93.934 64.1607 93.7939 63.5236 93.5135C62.8901 93.2296 62.3896 92.84 62.0219 92.3449C61.6578 91.8462 61.4703 91.271 61.4594 90.6193H63.8403C63.8549 90.8923 63.9441 91.1326 64.1079 91.3401C64.2754 91.544 64.4975 91.7024 64.7741 91.8152C65.0508 91.9281 65.3621 91.9845 65.7079 91.9845C66.0684 91.9845 66.3869 91.9208 66.6636 91.7934C66.9403 91.666 67.1569 91.4894 67.3134 91.2637C67.47 91.038 67.5483 90.7777 67.5483 90.4828C67.5483 90.1842 67.4645 89.9203 67.2971 89.6909C67.1332 89.4579 66.8966 89.2759 66.5871 89.1449C66.2813 89.0138 65.9173 88.9483 65.495 88.9483H64.4519V87.2117H65.495C65.8517 87.2117 66.1667 87.1498 66.4397 87.026C66.7164 86.9023 66.9312 86.7312 67.0841 86.5127C67.237 86.2907 67.3134 86.0322 67.3134 85.7373C67.3134 85.457 67.2461 85.2112 67.1114 85.0001C66.9803 84.7853 66.7947 84.6178 66.5544 84.4977C66.3177 84.3775 66.0411 84.3175 65.7243 84.3175C65.404 84.3175 65.1109 84.3757 64.8451 84.4922C64.5794 84.6051 64.3664 84.7671 64.2062 84.9782C64.046 85.1894 63.9605 85.4369 63.9495 85.7209H61.6833C61.6942 85.0765 61.8781 84.5086 62.2348 84.0171C62.5916 83.5256 63.0722 83.1416 63.6765 82.8649C64.2845 82.5845 64.9707 82.4444 65.7352 82.4444C66.507 82.4444 67.1824 82.5845 67.7612 82.8649C68.3401 83.1452 68.7897 83.5238 69.1101 84.0007C69.4341 84.474 69.5943 85.0055 69.5906 85.5953C69.5943 86.2215 69.3995 86.7439 69.0063 87.1626C68.6168 87.5812 68.1089 87.847 67.4827 87.9599V88.0472C68.3055 88.1528 68.9317 88.4386 69.3613 88.9046C69.7945 89.3669 70.0093 89.9458 70.0056 90.6411C70.0093 91.2782 69.8254 91.8443 69.4541 92.3395C69.0864 92.8346 68.5785 93.2241 67.9305 93.5081C67.2825 93.792 66.5398 93.934 65.7025 93.934Z"
        fill="#0F5291"
      />
      <rect
        x="59.91"
        y="79.1309"
        width="10.8926"
        height="17.3"
        rx="0.961111"
        stroke="#9FB3C8"
        strokeWidth="0.640741"
      />
      <path
        d="M80.3372 82.5973V93.7811H77.9726V84.8417H77.9071L75.346 86.4472V84.3502L78.1146 82.5973H80.3372Z"
        fill="#0F5291"
      />
      <rect
        x="72.7245"
        y="79.1309"
        width="10.8926"
        height="17.3"
        rx="0.961111"
        stroke="#9FB3C8"
        strokeWidth="0.640741"
      />
      <path
        d="M90.7304 91.8153V89.9532L95.3994 82.5974H97.0049V85.1749H96.0547L93.1113 89.833V89.9204H99.7462V91.8153H90.7304ZM96.0984 93.7812V91.2474L96.1421 90.4228V82.5974H98.3592V93.7812H96.0984Z"
        fill="#0F5291"
      />
      <rect
        x="89.3839"
        y="79.1309"
        width="10.8926"
        height="17.3"
        rx="0.961111"
        stroke="#9FB3C8"
        strokeWidth="0.640741"
      />
      <path
        d="M103.582 93.7812V92.0775L107.563 88.3914C107.902 88.0637 108.186 87.7688 108.415 87.5067C108.648 87.2446 108.825 86.9879 108.945 86.7367C109.065 86.4819 109.125 86.207 109.125 85.9122C109.125 85.5845 109.05 85.3024 108.901 85.0657C108.752 84.8254 108.548 84.6416 108.289 84.5142C108.031 84.3831 107.738 84.3176 107.41 84.3176C107.068 84.3176 106.769 84.3868 106.515 84.5251C106.26 84.6634 106.063 84.8618 105.925 85.1203C105.786 85.3788 105.717 85.6864 105.717 86.0432H103.473C103.473 85.3115 103.639 84.6762 103.97 84.1374C104.301 83.5986 104.765 83.1817 105.362 82.8868C105.959 82.592 106.647 82.4445 107.427 82.4445C108.227 82.4445 108.925 82.5865 109.518 82.8705C110.115 83.1508 110.579 83.5403 110.911 84.0391C111.242 84.5378 111.407 85.1094 111.407 85.7538C111.407 86.1761 111.324 86.5929 111.156 87.0043C110.992 87.4157 110.699 87.8726 110.277 88.375C109.855 88.8738 109.26 89.4726 108.491 90.1716L106.859 91.7717V91.8481H111.555V93.7812H103.582Z"
        fill="#0F5291"
      />
      <rect
        x="102.198"
        y="79.1309"
        width="10.8926"
        height="17.3"
        rx="0.961111"
        stroke="#9FB3C8"
        strokeWidth="0.640741"
      />
      <path
        d="M122.626 82.5973V93.7811H120.262V84.8417H120.196L117.635 86.4472V84.3502L120.404 82.5973H122.626Z"
        fill="#0F5291"
      />
      <rect
        x="115.014"
        y="79.1309"
        width="10.8926"
        height="17.3"
        rx="0.961111"
        stroke="#9FB3C8"
        strokeWidth="0.640741"
      />
      <g filter="url(#filter1_dd_10_3347)">
        <circle
          cx="87.7817"
          cy="119.178"
          r="12.4944"
          fill="white"
          stroke="#D9E2EC"
          strokeWidth="1.92222"
        />
        <path
          d="M87.7807 114.692V123.662M83.2955 119.177H92.2658"
          stroke="#0F5291"
          strokeWidth="1.28148"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_10_3347"
          x="26.9568"
          y="54.7905"
          width="119.087"
          height="85.7685"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1.59428"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_10_3347"
          />
          <feOffset dy="3.18857" />
          <feGaussianBlur stdDeviation="2.39143" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10_3347" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2.39143"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_10_3347"
          />
          <feOffset dy="7.97142" />
          <feGaussianBlur stdDeviation="5.97857" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_10_3347"
            result="effect2_dropShadow_10_3347"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_10_3347"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dd_10_3347"
          x="64.7605"
          y="104.128"
          width="46.0425"
          height="46.0425"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1.59428"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_10_3347"
          />
          <feOffset dy="3.18857" />
          <feGaussianBlur stdDeviation="2.39143" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10_3347" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2.39143"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_10_3347"
          />
          <feOffset dy="7.97142" />
          <feGaussianBlur stdDeviation="5.97857" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_10_3347"
            result="effect2_dropShadow_10_3347"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_10_3347"
            result="shape"
          />
        </filter>
      </defs>
    </>
  ),
});
