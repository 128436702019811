export enum Columns {
  Site = 'site',
  Groups = 'groups',
}

export type FilterKeys = Columns.Groups | Columns.Site;

export const FilterKeys = [Columns.Groups, Columns.Site] as const;

export type FilterOption = {
  column: FilterKeys;
  label: string;
  value: string;
};

export type FilterOptions = {
  [key in FilterKeys]: { all: FilterOption[]; enabled: FilterOption[] };
};
