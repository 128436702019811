import { chain } from 'lodash';
import { useMemo } from 'react';
import {
  Columns,
  FilterOptionServerSide,
  FilterOptionsServerSide,
} from '~components/displays/DisplayTable/constants';
import { statusMessageMap, StatusTypes } from '~components/displays/useStatus';
import { DataWithCustomer } from '~pages/[organization]/customers/[handle]/displays/Index';
import { isDefined } from '~utils/types';

export function useFilterOptionsServerSide(
  filters: Array<{ id: string; value: FilterOptionServerSide[] }>,
  data: DataWithCustomer,
): FilterOptionsServerSide {
  const filterMap = new Map(filters.map(({ id, value }) => [id, value]));

  const enabledGroupFilters: FilterOptionServerSide[] = filterMap.get('groups') ?? [];
  const enabledSiteFilters: FilterOptionServerSide[] = filterMap.get('site') ?? [];
  const enabledFirmwareFilters: FilterOptionServerSide[] = filterMap.get('firmware') ?? [];
  const enabledPlaylistFilters: FilterOptionServerSide[] = filterMap.get('playlist') ?? [];
  const enabledPowerScheduleFilters: FilterOptionServerSide[] =
    filterMap.get('powerSchedule') ?? [];
  const enabledWarningsFilters: FilterOptionServerSide[] = filterMap.get('warnings') ?? [];
  const enabledStatusFilters: FilterOptionServerSide[] = filterMap.get('status') ?? [];

  const groupFilters = useMemo<FilterOptionServerSide[]>(() => {
    return chain(data?.customer?.groups)
      .sortBy((g) => g.name.toLowerCase())
      .map(
        (group): FilterOptionServerSide => ({
          value: group.id,
          label: group.name,
          column: Columns.Groups,
          id: group.id,
        }),
      )
      .value();
  }, [data]);

  const siteFilters = useMemo<FilterOptionServerSide[]>(() => {
    return chain(data?.customer?.sites)
      .sortBy((l) => l.name.toLowerCase())
      .map(
        (site): FilterOptionServerSide => ({
          value: site.id,
          label: site.name,
          column: Columns.Site,
          id: site.id,
        }),
      )
      .value();
  }, [data]);

  const firmwareFilters = useMemo<FilterOptionServerSide[]>(() => {
    return chain(data?.customer?.displayFirmwareVersions)
      .map((row) => row)
      .filter(isDefined)
      .uniq()
      .sortBy((f) => f.toLowerCase())
      .map(
        (firmware): FilterOptionServerSide => ({
          value: firmware,
          label: firmware,
          column: Columns.Firmware,
          id: firmware,
        }),
      )
      .value();
  }, [data]);

  const playlistFilters = useMemo<FilterOptionServerSide[]>(() => {
    return chain(data?.customer?.playlists)
      .map((row) => ({
        title: row.title,
        id: row.id,
      }))
      .filter(isDefined)
      .uniq()
      .sortBy((title) => title)
      .map(
        ({ title, id }): FilterOptionServerSide => ({
          value: title,
          label: title,
          column: Columns.Playlist,
          id: id,
        }),
      )
      .value();
  }, [data]);

  const powerScheduleFilters = useMemo<FilterOptionServerSide[]>(() => {
    return chain(data?.customer?.powerSchedules)
      .map((row) => ({
        title: row.title,
        id: row.id,
      }))
      .filter(isDefined)
      .uniq()
      .sortBy((title) => title)
      .map(
        ({ title, id }): FilterOptionServerSide => ({
          value: title,
          label: title,
          column: Columns.PowerSchedule,
          id: id,
        }),
      )
      .value();
  }, [data]);

  const warningsFilters = useMemo<FilterOptionServerSide[]>(() => {
    return Object.keys(StatusTypes).map((key) => {
      const value = StatusTypes[key as keyof typeof StatusTypes];
      return {
        value: key,
        label: statusMessageMap[value],
        column: Columns.Warnings,
        id: key,
      };
    });
  }, []);

  const statusFilters = useMemo<FilterOptionServerSide[]>(() => {
    return [
      {
        value: 'Connected',
        label: 'Connected',
        column: Columns.Status,
        id: 'CONNECTED',
      },
      {
        value: 'Disconnected',
        label: 'Disconnected',
        column: Columns.Status,
        id: 'DISCONNECTED',
      },
    ];
  }, []);

  return {
    [Columns.Firmware]: {
      all: firmwareFilters,
      enabled: enabledFirmwareFilters,
    },
    [Columns.Groups]: {
      all: groupFilters,
      enabled: enabledGroupFilters,
    },
    [Columns.Site]: {
      all: siteFilters,
      enabled: enabledSiteFilters,
    },
    [Columns.Playlist]: {
      all: playlistFilters,
      enabled: enabledPlaylistFilters,
    },
    [Columns.PowerSchedule]: {
      all: powerScheduleFilters,
      enabled: enabledPowerScheduleFilters,
    },
    [Columns.Warnings]: {
      all: warningsFilters,
      enabled: enabledWarningsFilters,
    },
    [Columns.Status]: {
      all: statusFilters,
      enabled: enabledStatusFilters,
    },
    [Columns.ClaimCode]: {
      all: [],
      enabled: [],
    },
  };
}
