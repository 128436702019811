import {
  Divider,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Tooltip,
} from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useState } from 'react';
import { CloseIcon, SearchIcon } from '~components/ui/icons';

export const SearchBar = ({
  search,
  handleSetSearch,
}: {
  search: string;
  handleSetSearch: (searchText: string) => void;
}) => {
  const [searchText, setSearchText] = useState(search);

  const debouncedSetSearch = useMemo(
    () =>
      debounce((newSearch: string) => {
        const currentParams = new URLSearchParams(window.location.search);
        if (newSearch) {
          currentParams.set('search', newSearch);
        } else {
          currentParams.delete('search');
        }
        const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
        history.replaceState({}, window.document.title, newUrl);
        handleSetSearch(newSearch);
      }, 300),
    [handleSetSearch],
  );

  useEffect(() => {
    return () => {
      debouncedSetSearch.cancel();
    };
  }, [debouncedSetSearch]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchText(value);

    if (value === '') {
      debouncedSetSearch('');
    }
  };

  const handleSearchInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      debouncedSetSearch(searchText);
    }
  };

  const handleSearchClick = () => {
    debouncedSetSearch(searchText);
  };

  const handleClear = () => {
    setSearchText('');
    debouncedSetSearch('');
  };

  return (
    <InputGroup width="100%">
      <Input
        type="text"
        placeholder="Type to search..."
        value={searchText}
        onChange={handleInputChange}
        variant="outline"
        size="md"
        onKeyDown={handleSearchInputKeyDown}
      />
      <InputRightElement
        width="4.5rem"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Tooltip label="Clear search" fontSize="md">
          <IconButton
            aria-label="Clear search"
            icon={<CloseIcon />}
            size="sm"
            _hover={{ backgroundColor: 'transparent' }}
            color="blue.600"
            variant="ghost"
            onClick={handleClear}
            title="Clear search"
          />
        </Tooltip>
        <Divider orientation="vertical" height="60%" borderColor="gray.300" />
        <Tooltip label="Search" fontSize="md">
          <IconButton
            aria-label="Search"
            variant="ghost"
            _hover={{ backgroundColor: 'transparent' }}
            color="blue.600"
            icon={<SearchIcon />}
            size="sm"
            onClick={handleSearchClick}
            title="Search"
          />
        </Tooltip>
      </InputRightElement>
    </InputGroup>
  );
};
