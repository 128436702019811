/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type CodeNameCell_ClaimCodeFragment = { __typename: 'MultiClaimCodeDocument', id: string, name?: string | null };

export const CodeNameCell_ClaimCodeFragmentDoc = gql`
    fragment CodeNameCell_claimCode on MultiClaimCodeDocument {
  id
  name
}
    `;