import { gql } from '@apollo/client';
import { Box, Tag, Tooltip } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { ClaimCodeTable_ClaimCodeFragment } from '../__generated__/ClaimCodeTable.graphql';

interface GroupsCellProps
  extends PropsWithChildren<CellProps<ClaimCodeTable_ClaimCodeFragment, string[]>> {
  groups: Array<{ id: string; name: string }>;
}
export function GroupsCell({ value, groups }: GroupsCellProps) {
  const groupLabel = value
    .map((val) => {
      const matchedGroup = groups.find((group) => group.id === val);
      return matchedGroup ? { groupName: matchedGroup.name, id: matchedGroup.id } : null;
    })
    .filter(Boolean);
  const tooltip = groupLabel.map((group) => group?.groupName).join(', ');

  return (
    <Tooltip label={tooltip}>
      <Box
        position="relative"
        display="flex"
        flexWrap="nowrap"
        overflow="hidden"
        style={{
          WebkitMaskImage: 'linear-gradient(to right, black calc(100% - 8px), transparent)',
          WebkitMaskSize: '100%',
          WebkitMaskPosition: 'top left',
          WebkitMaskRepeat: 'no-repeat',
        }}
      >
        {value.length === 0 && (
          <Tag size="xs" variant="solid" colorScheme="gray" visibility="hidden">
            No groups
          </Tag>
        )}
        {groupLabel.map((group) => (
          <Box key={group?.id} marginRight="2">
            <Tag size="xs" variant="outline" colorScheme="blue" background="white">
              {group?.groupName}
            </Tag>
          </Box>
        ))}
      </Box>
    </Tooltip>
  );
}

GroupsCell.graphql = {
  fragments: {
    GroupsCell_claimCode: gql`
      fragment GroupsCell_claimCode on MultiClaimCodeDocument {
        id
        groups
      }
    `,
  },
};
