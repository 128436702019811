import { chain } from 'lodash';
import { useMemo } from 'react';
import {
  Columns,
  FilterOption,
  FilterOptions,
} from '~components/displays/ClaimCodeTable/constants';
import { DataWithGroupSite } from '~pages/[organization]/customers/[handle]/displays/claimCode';

export function useFilterOptions(
  filters: Array<{ id: string; value: FilterOption[] }>,
  data: DataWithGroupSite,
): FilterOptions {
  const filterMap = new Map(filters.map(({ id, value }) => [id, value]));

  const enabledGroupFilters: FilterOption[] = filterMap.get('groups') ?? [];
  const enabledSiteFilters: FilterOption[] = filterMap.get('site') ?? [];

  const groupFilters = useMemo<FilterOption[]>(() => {
    return chain(data?.customer?.groups)
      .sortBy((g) => g.name.toLowerCase())
      .map(
        (group): FilterOption => ({
          value: group.id,
          label: group.name,
          column: Columns.Groups,
        }),
      )
      .value();
  }, [data]);

  const siteFilters = useMemo<FilterOption[]>(() => {
    return chain(data?.customer?.sites)
      .sortBy((l) => l.name.toLowerCase())
      .map(
        (site): FilterOption => ({
          value: site.id,
          label: site.name,
          column: Columns.Site,
        }),
      )
      .value();
  }, [data]);

  return {
    [Columns.Groups]: {
      all: groupFilters,
      enabled: enabledGroupFilters,
    },
    [Columns.Site]: {
      all: siteFilters,
      enabled: enabledSiteFilters,
    },
  };
}
