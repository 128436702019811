/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type ActiveMultiClaimUpdateMutationVariables = Types.Exact<{
  input: Types.ActivateMultiClaimCodeInput;
}>;


export type ActiveMultiClaimUpdateMutation = { __typename: 'Mutation', activateMultiClaimCode: { __typename: 'MultiClaimCodeDocument', id: string, code: string, createdAt: any } };

export type DisplayInlineGrouppsSelect_GroupCreateMutationVariables = Types.Exact<{
  input: Types.GroupCreateInput;
}>;


export type DisplayInlineGrouppsSelect_GroupCreateMutation = { __typename: 'Mutation', groupCreate: { __typename: 'GroupCreatePayload', group?: { __typename: 'Group', id: string, name: string } | null, customer?: { __typename: 'Customer', id: string, groups: Array<{ __typename: 'Group', id: string, name: string }> } | null } };

export type BulkClaimDisplayModal_CustomerFragment = { __typename: 'Customer', id: string, groups: Array<{ __typename: 'Group', id: string, name: string }>, sites: Array<{ __typename: 'Site', name: string, id: string }> };

export const BulkClaimDisplayModal_CustomerFragmentDoc = gql`
    fragment BulkClaimDisplayModal_customer on Customer {
  id
  groups {
    id
    name
  }
  sites {
    name
    id
  }
}
    `;
export const ActiveMultiClaimUpdateDocument = gql`
    mutation activeMultiClaimUpdate($input: ActivateMultiClaimCodeInput!) {
  activateMultiClaimCode(input: $input) {
    id
    code
    createdAt
  }
}
    `;
export type ActiveMultiClaimUpdateMutationFn = ApolloReactCommon.MutationFunction<ActiveMultiClaimUpdateMutation, ActiveMultiClaimUpdateMutationVariables>;

/**
 * __useActiveMultiClaimUpdateMutation__
 *
 * To run a mutation, you first call `useActiveMultiClaimUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActiveMultiClaimUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activeMultiClaimUpdateMutation, { data, loading, error }] = useActiveMultiClaimUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActiveMultiClaimUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActiveMultiClaimUpdateMutation, ActiveMultiClaimUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ActiveMultiClaimUpdateMutation, ActiveMultiClaimUpdateMutationVariables>(ActiveMultiClaimUpdateDocument, options);
      }
export type ActiveMultiClaimUpdateMutationHookResult = ReturnType<typeof useActiveMultiClaimUpdateMutation>;
export type ActiveMultiClaimUpdateMutationResult = ApolloReactCommon.MutationResult<ActiveMultiClaimUpdateMutation>;
export type ActiveMultiClaimUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ActiveMultiClaimUpdateMutation, ActiveMultiClaimUpdateMutationVariables>;
export const DisplayInlineGrouppsSelect_GroupCreateDocument = gql`
    mutation DisplayInlineGrouppsSelect_GroupCreate($input: GroupCreateInput!) {
  groupCreate(input: $input) {
    group {
      id
      name
    }
    customer {
      id
      groups {
        id
        name
      }
    }
  }
}
    `;
export type DisplayInlineGrouppsSelect_GroupCreateMutationFn = ApolloReactCommon.MutationFunction<DisplayInlineGrouppsSelect_GroupCreateMutation, DisplayInlineGrouppsSelect_GroupCreateMutationVariables>;

/**
 * __useDisplayInlineGrouppsSelect_GroupCreateMutation__
 *
 * To run a mutation, you first call `useDisplayInlineGrouppsSelect_GroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisplayInlineGrouppsSelect_GroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [displayInlineGrouppsSelectGroupCreateMutation, { data, loading, error }] = useDisplayInlineGrouppsSelect_GroupCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisplayInlineGrouppsSelect_GroupCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisplayInlineGrouppsSelect_GroupCreateMutation, DisplayInlineGrouppsSelect_GroupCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DisplayInlineGrouppsSelect_GroupCreateMutation, DisplayInlineGrouppsSelect_GroupCreateMutationVariables>(DisplayInlineGrouppsSelect_GroupCreateDocument, options);
      }
export type DisplayInlineGrouppsSelect_GroupCreateMutationHookResult = ReturnType<typeof useDisplayInlineGrouppsSelect_GroupCreateMutation>;
export type DisplayInlineGrouppsSelect_GroupCreateMutationResult = ApolloReactCommon.MutationResult<DisplayInlineGrouppsSelect_GroupCreateMutation>;
export type DisplayInlineGrouppsSelect_GroupCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<DisplayInlineGrouppsSelect_GroupCreateMutation, DisplayInlineGrouppsSelect_GroupCreateMutationVariables>;