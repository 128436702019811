import { gql } from '@apollo/client';
import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { ReactNode, useMemo } from 'react';
import {
  CellProps,
  Column,
  TableInstance,
  useFilters,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { ChevronDownIcon, ChevronUpIcon } from '~components/ui/icons';
import { compareMaybeStrings } from '~utils/compare';
import { MaybePromise } from '~utils/types';
import {
  ClaimCodeTable_ClaimCodeFragment,
  CustomerClaimCodeTable_ClaimCodeFragment,
} from './__generated__/ClaimCodeTable.graphql';
import { CodeCell } from './Cell/CodeCell';
import { CodeNameCell } from './Cell/CodeNameCell';
import { ExpiryOnCell } from './Cell/ExpiryOnCell';
import { GroupsCell } from './Cell/GroupsCell';
import { LocationCell } from './Cell/LocationCell';
import { PrefixCell } from './Cell/PrefixCell';
import { RevokeCell } from './Cell/RevokeCell';
import { ValidityCell } from './Cell/ValidityCell';
import { Filter, filterByGroups, filterBySite } from './Filtering/Filter';
import { ClaimCodeTablePageSizeSelector, ClaimCodeTablePagination } from './Pagination';
import { SearchBar } from './SearchBar';

export enum Columns {
  Code = 'code',
  CodeName = 'codeName',
  Prefix = 'prefix',
  Location = 'location',
  Groups = 'groups',
  ExpiryOn = 'expiryOn',
  Validity = 'validity',
  Revoke = 'revoke',
}

export function useClaimCodeTable({
  data,
  customerData,
  pageIndex,
  pageSize,
  totalRowCount,
}: {
  data: ClaimCodeTable_ClaimCodeFragment[];
  customerData: CustomerClaimCodeTable_ClaimCodeFragment;
  pageIndex: number;
  pageSize: number;
  totalRowCount: number;
}) {
  const columns = useMemo<Array<Column<ClaimCodeTable_ClaimCodeFragment>>>(
    () => [
      {
        id: Columns.Code,
        Header: 'Code',
        accessor: (p) => p.code,
        Cell: CodeCell,
        width: '100px',
        minWidth: 100,
        maxWidth: 100,
      },
      {
        id: Columns.CodeName,
        Header: 'Code name',
        accessor: (p) => p.name,
        sortType: (rowA, rowB) => compareMaybeStrings(rowA.original.name, rowB.original.name),
        Cell: CodeNameCell,
        width: '140px',
        minWidth: 140,
        maxWidth: 140,
      },
      {
        id: Columns.Prefix,
        Header: 'Prefix',
        accessor: (p) => p.prefix,
        sortType: (rowA, rowB) => compareMaybeStrings(rowA.original.prefix, rowB.original.prefix),
        Cell: PrefixCell,
        width: '120px',
        minWidth: 120,
        maxWidth: 120,
      },
      {
        id: Columns.Location,
        Header: 'Location',
        accessor: (d) => d.site,
        filter: filterBySite,
        sortType: (rowA, rowB) => compareMaybeStrings(rowA.original.site, rowB.original.site),
        Cell: (props: CellProps<ClaimCodeTable_ClaimCodeFragment, string>) => (
          <LocationCell {...props} sites={customerData?.sites} />
        ),
        width: '120px',
        minWidth: 120,
        maxWidth: 120,
      },
      {
        id: Columns.Groups,
        Header: 'Groups',
        accessor: (d) => d.groups,
        filter: filterByGroups,
        Cell: (props: CellProps<ClaimCodeTable_ClaimCodeFragment, string[]>) => (
          <GroupsCell {...props} groups={customerData?.groups} />
        ),
        disableSortBy: true,
        width: '150px',
        maxWidth: 150,
        minWidth: 150,
      },

      {
        id: Columns.ExpiryOn,
        Header: 'Expiry on',
        accessor: (d) => d.expiry,
        Cell: ExpiryOnCell,
        width: '100px',
        maxWidth: 100,
        minWidth: 100,
      },
      {
        id: Columns.Validity,
        Header: 'Validity',
        accessor: (d) => d.expiry,
        Cell: ValidityCell,
        width: '100px',
        maxWidth: 100,
        minWidth: 100,
      },
      {
        id: Columns.Revoke,
        Header: 'Revoke',
        accessor: (d) => d,
        disableSortBy: true,
        Cell: RevokeCell,
        width: '100px',
        maxWidth: 100,
        minWidth: 100,
      },
    ],
    [customerData?.groups, customerData?.sites],
  );

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex,
        pageSize,
      },
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetSelectedRows: false,
      autoResetPage: false,
      manualPagination: true,
      pageCount: Math.ceil(totalRowCount / pageSize),
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  return table;
}

/* eslint-disable react/jsx-key */
export function ClaimCodeTable({
  table,
  onGoToDisplays,
}: {
  table: TableInstance<ClaimCodeTable_ClaimCodeFragment>;
  onGoToDisplays: (id: string, code: string, name: string) => MaybePromise<void>;
}) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = table;

  return (
    <Table {...getTableProps()} variant="simple" width="100%">
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              const headerProps = column.getHeaderProps(column.getSortByToggleProps());
              return (
                <Th
                  {...headerProps}
                  color={column.isSorted ? 'gray.700' : 'gray.600'}
                  minWidth={`${column.minWidth}px`}
                  maxWidth={`${column.maxWidth}px`}
                  width={column.width}
                  _hover={
                    column.disableSortBy
                      ? {}
                      : {
                          textColor: 'gray.800',
                        }
                  }
                  onClick={(e) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (headerProps as any).onClick?.(e);
                  }}
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Box flex="1">{column.render('Header') as ReactNode}</Box>
                    {column.canSort && (
                      <Box>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ChevronDownIcon display="inherit" />
                          ) : (
                            <ChevronUpIcon display="inherit" />
                          )
                        ) : (
                          <ChevronDownIcon visibility="hidden" />
                        )}
                      </Box>
                    )}
                  </Box>
                </Th>
              );
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);

          return (
            <Tr
              {...row.getRowProps()}
              role="button"
              _hover={{
                background: 'blue.50',
                cursor: 'pointer',
              }}
            >
              {row.cells.map((cell) => {
                return (
                  <Td
                    minWidth={`${cell.column.minWidth}px`}
                    maxWidth={`${cell.column.maxWidth}px`}
                    width={cell.column.width}
                    onClick={(e) => {
                      if (cell.column.id === Columns.Revoke) {
                        e.stopPropagation();
                      } else {
                        onGoToDisplays(
                          row.original.id,
                          row.original.code,
                          row.original.name ?? 'Unknown',
                        );
                      }
                    }}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell') as ReactNode}
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}

ClaimCodeTable.Filter = Filter;
ClaimCodeTable.Pagination = ClaimCodeTablePagination;
ClaimCodeTable.PageSizeSelector = ClaimCodeTablePageSizeSelector;
ClaimCodeTable.SearchSelector = SearchBar;
/* eslint-enable react/jsx-key */

ClaimCodeTable.graphql = {
  fragments: {
    ClaimCodeTable_claimCode: gql`
      fragment ClaimCodeTable_claimCode on MultiClaimCodeDocument {
        id
        ...CodeCell_claimCode
        ...CodeNameCell_claimCode
        ...ExpiryOnCell_claimCode
        ...GroupsCell_claimCode
        ...LocationCell_claimCode
        ...PrefixCell_claimCode
        ...ValidityCell_claimCode
      }
    `,
    CustomerClaimCodeTable_claimCode: gql`
      fragment CustomerClaimCodeTable_claimCode on Customer {
        id
        groups {
          id
          name
        }
        sites {
          name
          id
        }
      }
    `,
  },
};
