/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type PrefixCell_ClaimCodeFragment = { __typename: 'MultiClaimCodeDocument', id: string, prefix?: string | null };

export const PrefixCell_ClaimCodeFragmentDoc = gql`
    fragment PrefixCell_claimCode on MultiClaimCodeDocument {
  id
  prefix
}
    `;