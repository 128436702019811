import { gql } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { ClaimCodeTable_ClaimCodeFragment } from '../__generated__/ClaimCodeTable.graphql';

export function PrefixCell({
  value,
}: PropsWithChildren<CellProps<ClaimCodeTable_ClaimCodeFragment, number>>) {
  return (
    <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" fontWeight="semibold">
      {value}
    </Box>
  );
}

PrefixCell.graphql = {
  fragments: {
    PrefixCell_claimCode: gql`
      fragment PrefixCell_claimCode on MultiClaimCodeDocument {
        id
        prefix
      }
    `,
  },
};
