import {
  Box,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ImagePlaylistTypeIllustration } from '~components/ui/illustrations/ImagePlaylistTypeIllustration';
import { MixedPlaylistTypeIllustration } from '~components/ui/illustrations/MixedPlaylistTypeIllustration';
import { PlaylistCreateInput, PlaylistType } from '~graphql/__generated__/types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (value: PlaylistCreateInput['playlistType']) => void;
}

export function SelectPlaylistTypeModal({ isOpen, onClose, onSelect }: Props) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      closeOnOverlayClick={false}
      closeOnEsc={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Playlist Type</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            {/* Single Display Claim */}
            <Box
              as="button"
              onClick={() => onSelect(PlaylistType.Mixed)}
              w="100%"
              p={4}
              borderRadius="xl"
              bg="#F1F8FE"
              _hover={{ bg: 'blue.100' }}
              textAlign="left"
            >
              <Flex align="center">
                <Box mr={8}>
                  <Icon as={MixedPlaylistTypeIllustration} boxSize={36} color="blue.500" />
                </Box>
                <Box>
                  <Text mb={4} fontWeight="bold" fontSize="lg">
                    Images and Videos
                  </Text>
                  <Text fontSize="sm" color="gray.600">
                    Devices that support both Images and Audio/Video. Devices of Q-Line, D-Line,
                    E-Line are supported.Please refer www.ppds.com for more details.
                  </Text>
                </Box>
              </Flex>
            </Box>

            {/* Bulk Display Claim */}
            <Box
              as="button"
              onClick={() => onSelect(PlaylistType.Image)}
              w="100%"
              p={4}
              borderRadius="xl"
              bg={'#F1F8FE'}
              color={'black'}
              _hover={{ bg: 'blue.100' }}
              textAlign="left"
            >
              <Flex align="center">
                <Box mr={8}>
                  <Icon as={ImagePlaylistTypeIllustration} boxSize={36} color="green.500" />
                </Box>
                <Box>
                  <Text mb={4} fontWeight="bold" fontSize="lg">
                    Images only
                  </Text>
                  <Text fontSize="sm" color={'gray.600'}>
                    For devices that support only images. Only I-Series of displays are
                    supported.Please refer www.ppds.com for more details.
                  </Text>
                </Box>
              </Flex>
            </Box>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
