/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteMultiClaimCodeMutationVariables = Types.Exact<{
  input: Types.DeleteMultiClaimCodeInput;
}>;


export type DeleteMultiClaimCodeMutation = { __typename: 'Mutation', deleteMultiClaimCode: string };

export type RevokeCell_ClaimCodeFragment = { __typename: 'MultiClaimCodeDocument', id: string, name?: string | null };

export const RevokeCell_ClaimCodeFragmentDoc = gql`
    fragment RevokeCell_claimCode on MultiClaimCodeDocument {
  id
  name
}
    `;
export const DeleteMultiClaimCodeDocument = gql`
    mutation DeleteMultiClaimCode($input: DeleteMultiClaimCodeInput!) {
  deleteMultiClaimCode(input: $input)
}
    `;
export type DeleteMultiClaimCodeMutationFn = ApolloReactCommon.MutationFunction<DeleteMultiClaimCodeMutation, DeleteMultiClaimCodeMutationVariables>;

/**
 * __useDeleteMultiClaimCodeMutation__
 *
 * To run a mutation, you first call `useDeleteMultiClaimCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMultiClaimCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMultiClaimCodeMutation, { data, loading, error }] = useDeleteMultiClaimCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMultiClaimCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMultiClaimCodeMutation, DeleteMultiClaimCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteMultiClaimCodeMutation, DeleteMultiClaimCodeMutationVariables>(DeleteMultiClaimCodeDocument, options);
      }
export type DeleteMultiClaimCodeMutationHookResult = ReturnType<typeof useDeleteMultiClaimCodeMutation>;
export type DeleteMultiClaimCodeMutationResult = ApolloReactCommon.MutationResult<DeleteMultiClaimCodeMutation>;
export type DeleteMultiClaimCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMultiClaimCodeMutation, DeleteMultiClaimCodeMutationVariables>;