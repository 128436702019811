import { createIcon } from '@chakra-ui/react';

export const MixedPlaylistTypeIllustration = createIcon({
  displayName: 'SingleDisplayIllustration',
  viewBox: '0 0 173 173',
  defaultProps: {
    fill: 'none',
    width: '173px',
    height: '173px',
  },
  path: (
    <>
      <path
        d="M149 75C149 115.869 115.869 149 75 149C34.1309 149 1 115.869 1 75C1 34.1309 34.1309 1 75 1C115.869 1 149 34.1309 149 75Z"
        fill="#F0F4F8"
        stroke="#0F5291"
        strokeWidth="2"
      />
      <g filter="url(#filter0_dd_305_34482)">
        <path
          d="M31.6641 53.3351C31.6641 50.8805 33.6539 48.8906 36.1085 48.8906H113.886C116.341 48.8906 118.331 50.8805 118.331 53.3351V98.8906C118.331 101.345 116.341 103.335 113.886 103.335H36.1085C33.6539 103.335 31.6641 101.345 31.6641 98.8906V53.3351Z"
          fill="white"
        />
        <path
          d="M53.8863 103.335H97.7751V104.446C97.7751 105.673 96.7802 106.668 95.5529 106.668H56.1085C54.8812 106.668 53.8863 105.673 53.8863 104.446V103.335Z"
          fill="white"
        />
        <path
          d="M53.8863 102.224H52.7752H36.1085C34.2676 102.224 32.7752 100.732 32.7752 98.8906V53.3351C32.7752 51.4941 34.2676 50.0017 36.1085 50.0017H113.886C115.727 50.0017 117.22 51.4941 117.22 53.3351V98.8906C117.22 100.732 115.727 102.224 113.886 102.224H98.8862H97.7751H53.8863ZM54.9974 104.446H96.664C96.664 105.06 96.1666 105.557 95.5529 105.557H56.1085C55.4948 105.557 54.9974 105.06 54.9974 104.446Z"
          stroke="#0F5291"
          strokeWidth="2.22222"
        />
      </g>
      <path
        d="M63 66H45C44 66 43 67 43 68V82C43 83.1 43.9 84 45 84H63C64 84 65 83 65 82V68C65 67 64 66 63 66ZM63 81.92C62.98 81.95 62.94 81.98 62.92 82H45V68.08L45.08 68H62.91C62.94 68.02 62.97 68.06 62.99 68.08V81.92H63ZM53 78.51L50.5 75.5L47 80H61L56.5 74L53 78.51Z"
        fill="#0F5291"
      />
      <path
        d="M98 71V79H88V71H98ZM99 69H87C86.45 69 86 69.45 86 70V80C86 80.55 86.45 81 87 81H99C99.55 81 100 80.55 100 80V76.5L104 80.5V69.5L100 73.5V70C100 69.45 99.55 69 99 69Z"
        fill="#0F5291"
      />
      <defs>
        <filter
          id="filter0_dd_305_34482"
          x="23.3701"
          y="47.5083"
          width="103.252"
          height="74.3658"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1.38233"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_305_34482"
          />
          <feOffset dy="2.76465" />
          <feGaussianBlur stdDeviation="2.07349" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_305_34482" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2.07349"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_305_34482"
          />
          <feOffset dy="6.91164" />
          <feGaussianBlur stdDeviation="5.18373" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_305_34482"
            result="effect2_dropShadow_305_34482"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_305_34482"
            result="shape"
          />
        </filter>
      </defs>
    </>
  ),
});
