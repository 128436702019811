/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type LocationCell_ClaimCodeFragment = { __typename: 'MultiClaimCodeDocument', id: string, site?: string | null };

export const LocationCell_ClaimCodeFragmentDoc = gql`
    fragment LocationCell_claimCode on MultiClaimCodeDocument {
  id
  site
}
    `;