import { ApolloError, gql } from '@apollo/client';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { GraphQLErrors } from '@apollo/client/errors';
import { Box, Button, Link, Tag, useDisclosure, VStack } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { isNil } from 'lodash';
import isNumber from 'lodash/isNumber';
import uniq from 'lodash/uniq';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Shield } from '~auth/Shield';
import { useAuth } from '~auth/useAuth';
import {
  BulkActionModal_CustomerFragment,
  BulkActionModal_DisplayFragment,
  BulkActionModal_OrganizationFragment,
} from '~components/displays/BulkAction/__generated__/BulkActionModal.graphql';
import { BulkActionButton } from '~components/displays/BulkAction/BulkActionButton';
import { BulkActionModal } from '~components/displays/BulkAction/BulkActionModal';
import { BulkClaimDisplayModal_CustomerFragment } from '~components/displays/ClaimDisplay/__generated__/BulkClaimDisplayModal.graphql';
import { BulkClaimModal } from '~components/displays/ClaimDisplay/BulkClaimDisplayModal';
import { ClaimDisplayModal } from '~components/displays/ClaimDisplay/ClaimDisplayModal';
import { SelectClaimModal } from '~components/displays/ClaimDisplay/selectClaimModal';
import {
  DisplayTable,
  DisplayTableServerSide,
  useDisplayTable,
  useDisplayTableServerSide,
} from '~components/displays/DisplayTable';
import { Columns, FilterOptionServerSide } from '~components/displays/DisplayTable/constants';
import {
  DisplayTableContext,
  DisplayTableProvider,
} from '~components/displays/DisplayTable/DisplayTableContext';
import { useFilterOptions } from '~components/displays/DisplayTable/Filtering/useFilterOptions';
import { useFilterOptionsServerSide } from '~components/displays/DisplayTable/Filtering/useFilterOptionsServerSide';
import { DisplaysQueryContext } from '~components/displays/DisplayTable/useDisplaysQuery';
// import { getFiltersFromUrl,useDisplaysQuery } from '~components/displays/DisplayTable/useDisplaysQuery';
import { EmptyView, EmptyViewButton } from '~components/EmptyView';
import UpgradeToWaveEssentialModal from '~components/organization/UpgradeToWaveEssentialModal/UpgradeToWaveEssentialModal';
import { StatisticsItem } from '~components/statistics/StatisticsBar';
import UsageStatistics from '~components/statistics/UsageStatistics';
import { BackButton } from '~components/ui/BackButton';
import { HelpIcon } from '~components/ui/icons';
import { EmptyDisplayIllustration } from '~components/ui/illustrations/EmptyDisplayIllustration';
import { Page } from '~components/ui/Page';
import { PageActions } from '~components/ui/PageActions';
import { PageContent } from '~components/ui/PageContent';
import { PageHeading } from '~components/ui/PageHeading';
import { PageLoader } from '~components/ui/PageLoader';
import { useConditionalPolling } from '~components/useConditionalPolling';
import { Exact } from '~graphql/__generated__/types';
import { HandleApiError } from '~graphql/HandleApiError';
import { useAnalyticsReporter } from '~utils/analytics';
import { useFeatureFlag } from '~utils/features';
import { isWaveSubscription } from '~utils/subscriptions';
import { ensure, isDefined } from '~utils/types';
import {
  CustomerDisplaysIndexPageQuery,
  useCustomerDisplaysIndexPageQuery,
} from './__generated__/Index.graphql';

export type DataWithCustomer = Omit<CustomerDisplaysIndexPageQuery, 'customer'> & {
  customer: CustomerDisplaysIndexPageQuery['customer'];
};
type DisplayOverviewHeadingProps = {
  data: DataWithCustomer;
};
type DisplayOverviewHeadingPropsServerSide = {
  data: DataWithCustomer;
  filtersState: DisplayFilters;
  handleSetFilters: (filterId: string, newValue: FilterOptionServerSide[]) => void;
  resetFilters: () => void;
};
export type DisplayFilters = Array<{ id: string; value: FilterOptionServerSide[] }>;

type CustomerDisplaysOverviewPropsServerSide = {
  data: DataWithCustomer;
  error: ApolloError | undefined;
  refetch: (
    variables?: Partial<Exact<{ customerHandle: string }>>,
  ) => Promise<ApolloQueryResult<CustomerDisplaysIndexPageQuery>>;
  pageIndex: number;
  pageSize: number;
  filters: DisplayFilters;
  totalRowCount: number;
  totalDisplays: number;
  search: string;
  handleSetSearch: (searchText: string) => void;
  handleSetPageSize: (pageSize: number) => void;
  handleSetPageIndex: (pageIndex: number) => void;
  handleSetFilters: (filterId: string, newValue: FilterOptionServerSide[]) => void;
};
type CustomerDisplaysOverviewProps = {
  data: DataWithCustomer;
  error: ApolloError | undefined;
  refetch: (
    variables?: Partial<Exact<{ customerHandle: string }>>,
  ) => Promise<ApolloQueryResult<CustomerDisplaysIndexPageQuery>>;
};

function DisplayOverviewHeadingServerSide({
  data,
  filtersState,
  handleSetFilters,
  resetFilters,
}: DisplayOverviewHeadingPropsServerSide) {
  const navigate = useNavigate();
  const { setDisplaysQuery } = useContext(DisplaysQueryContext);
  const tableContext = useContext(DisplayTableContext);
  const { isEnabled: isMultiClaimEnabled } = useFeatureFlag('multiClaim');

  if (isNil(tableContext)) {
    throw new Error('TableContext can only be used within TableContext Provider');
  }

  const { table } = tableContext;

  const handleGoBack = () => {
    navigate('/');
  };
  const handleGoToClaimCode = () => {
    if (!setDisplaysQuery) return;
    setDisplaysQuery(new URLSearchParams());
    navigate('../displays/claimCode');
  };

  const hasWaveSubscription = isWaveSubscription(data.customer?.waveSubscription);

  const numberOfDisplays = data.customer?.displays.length ?? 0;

  const shouldShowStatistics = hasWaveSubscription && numberOfDisplays > 0;
  const filters = useFilterOptionsServerSide(filtersState ?? [], data);

  const handleItemClicked = (item: StatisticsItem) => {
    const itemCanApplyFilter = ['Connected', 'Disconnected', 'Standby'].includes(item.name);

    const valueLen = filtersState?.find((f) => f.id === 'warnings')?.value?.length;
    if (valueLen && valueLen > 0) {
      if (itemCanApplyFilter) {
        table?.setAllFilters([
          {
            id: Columns.Site,
            value: [],
          },
          {
            id: Columns.Groups,
            value: [],
          },
          {
            id: Columns.Firmware,
            value: [],
          },
          {
            id: Columns.Playlist,
            value: [],
          },
          {
            id: Columns.PowerSchedule,
            value: [],
          },
          {
            id: Columns.Warnings,
            value: [],
          },
          {
            id: Columns.Status,
            value: [],
          },
        ]);
        table?.setFilter(
          Columns.Status,
          filters.status.all.filter((f) => f.value === item.name),
        );
      }
      return;
    }
    if (itemCanApplyFilter) {
      resetFilters();
      handleSetFilters(
        'status',
        filters.status.all.filter((f) => f.value === item.name),
      );
    }
  };

  async function handleClaimCodeClicked() {
    navigate(`claimCode`, { state: { canGoBack: true } });
  }
  const { displaysQuery } = useContext(DisplaysQueryContext);
  const claimCodeParam = displaysQuery.get('claimCode');
  let code = '';
  let name = '';
  if (claimCodeParam) {
    [code, name] = claimCodeParam.split('|');
  }
  const claimCodeHistoryButton = (
    <Button variant="solid" colorScheme="blue" onClick={handleClaimCodeClicked}>
      View claim code history
    </Button>
  );
  return (
    <>
      {claimCodeParam ? (
        <PageHeading
          floatingButton={<BackButton onClick={handleGoToClaimCode} />}
          description="Devices claimed using the above claim code"
        >
          {code}
          <Tag variant="outline" size="sm" mt="3" ml="8">
            {name}
          </Tag>
        </PageHeading>
      ) : hasWaveSubscription && isMultiClaimEnabled ? (
        <PageHeading
          floatingButton={<BackButton onClick={handleGoBack} />}
          actions={claimCodeHistoryButton}
        >
          Display overview
        </PageHeading>
      ) : (
        <PageHeading floatingButton={<BackButton onClick={handleGoBack} />}>
          Display overview
        </PageHeading>
      )}
      {shouldShowStatistics && (
        <UsageStatistics customer={data.customer} onItemClicked={handleItemClicked} />
      )}
    </>
  );
}

function DisplayOverviewServerSide({
  data,
  error,
  refetch,
  pageIndex,
  pageSize,
  filters,
  handleSetPageSize,
  handleSetPageIndex,
  totalRowCount,
  totalDisplays,
  handleSetFilters,
  search,
  handleSetSearch,
}: CustomerDisplaysOverviewPropsServerSide) {
  const { customer } = useParams();
  const navigate = useNavigate();
  const claimDisplayModal = useDisclosure();
  const bulkActionModal = useDisclosure();
  const upgradeToWaveEssentialModal = useDisclosure();
  const analytics = useAnalyticsReporter();
  const { verifyUserPermissions } = useAuth();
  const bulkClaimModal = useDisclosure();
  const { isEnabled: isMultiClaimEnabled } = useFeatureFlag('multiClaim');

  // Remove once subscriptions are released to everyone: https://inthepocket.atlassian.net/browse/TPVWAVE-1460
  const { isEnabled: isSubscriptionsEnabled } = useFeatureFlag('subscriptions');

  const table = useDisplayTableServerSide({
    data,
    pageIndex,
    pageSize,
    totalRowCount,
  });

  const tableContext = useContext(DisplayTableContext);

  if (isNil(tableContext)) {
    throw new Error('TableContext can only be used within TableContext Provider');
  }

  const hasWaveSubscription = isWaveSubscription(data.customer?.waveSubscription);
  const [bulkClaimCode, setBulkClaimCode] = useState<string>('');
  const [bulkClaimId, setBulkClaimId] = useState<string>('');

  const { setTable } = tableContext;

  useEffect(() => {
    setTable?.(table);
  }, [table, setTable]);

  const selectedCount = table.selectedFlatRows.length;

  async function handleDisplayClicked(id: string) {
    analytics.track('displayDetail');
    navigate(id);
  }

  async function handleClaimDisplay() {
    analytics.track('displayClaimStart');

    if (hasWaveSubscription && isMultiClaimEnabled) {
      setIsSelectModalOpen(true);
    } else {
      setIsSingleClaimModalOpen(true);
    }
  }
  async function handleClose() {
    setIsSingleClaimModalOpen(false);
  }
  async function handleCreateSuccess({ id, code }: { id: string; code: string }) {
    setIsSelectModalOpen(false);
    setIsBulkClaimModalOpen(true);
    setBulkClaimCode(code);
    setBulkClaimId(id);
  }

  async function handleMultiClaimCodeActivate() {
    setIsBulkClaimModalOpen(false);
  }

  async function handleClaimDisplaySucceeded(display: { id: string }) {
    analytics.track('displayClaimComplete');
    claimDisplayModal.onClose();
    await refetch({ customerHandle: customer });
    navigate(display.id);
  }

  async function handleBulkActionSucceeded() {
    bulkActionModal.onClose();
    await refetch({ customerHandle: customer });
  }

  const extractInvalidDisplayIds = (graphQLErrors: GraphQLErrors | undefined): string[] => {
    if (!graphQLErrors) {
      return [];
    }

    const invalidDisplayIds = graphQLErrors?.map(({ path }) => {
      if (!path) {
        return;
      }

      const [c, d, index] = path;
      if (c === 'customer' && d === 'displays' && isNumber(index)) {
        return data?.customer?.displays[index as number]?.id;
      }
    });

    return uniq(invalidDisplayIds?.filter((id) => isDefined(id))) as string[];
  };

  const openBulkActions = () => {
    // Remove once subscriptions are released to everyone: https://inthepocket.atlassian.net/browse/TPVWAVE-1460
    if (isSubscriptionsEnabled && hasLiteSubscription) {
      upgradeToWaveEssentialModal.onOpen();
      return;
    }

    analytics.track('displayOpenBulkActions');
    bulkActionModal.onOpen();
  };

  const hasLiteSubscription = !isWaveSubscription(data.customer?.waveSubscription);

  const hasDisplayClaimPermission = verifyUserPermissions([Permission.DisplayClaim]);
  const { displaysQuery } = useContext(DisplaysQueryContext);
  const claimCodeParam = displaysQuery.get('claimCode');
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [isSingleClaimModalOpen, setIsSingleClaimModalOpen] = useState(false);
  const [isBulkClaimModalOpen, setIsBulkClaimModalOpen] = useState(false);
  const handleBackToSelect = () => {
    setIsSingleClaimModalOpen(false);
    setIsBulkClaimModalOpen(false);
    setIsSelectModalOpen(true);
  };
  return (
    <>
      {totalDisplays > 0 && (
        <PageActions
          left={
            <>
              {selectedCount > 0 ? (
                <BulkActionButton selectedCount={selectedCount} onClick={openBulkActions} />
              ) : null}
              <DisplayTableServerSide.Filter
                table={table}
                data={data}
                filtersState={filters}
                handleSetFilters={handleSetFilters}
              />
            </>
          }
          right={
            claimCodeParam ? null : (
              <Shield requiredPermissions={[Permission.DisplayClaim]}>
                <Button variant="solid" colorScheme="blue" onClick={handleClaimDisplay}>
                  Claim display
                </Button>
              </Shield>
            )
          }
          searchbar={
            <DisplayTableServerSide.SearchSelector
              search={search}
              handleSetSearch={handleSetSearch}
            />
          }
        />
      )}

      <Box
        boxShadow="elevated"
        border="1px solid"
        borderRadius="md"
        borderColor="gray.100"
        overflowX="auto"
        bgColor="white"
      >
        {totalDisplays === 0 ? (
          <VStack paddingTop={20} paddingBottom={20} spacing={8}>
            <EmptyView
              icon={<EmptyDisplayIllustration />}
              title="Claim your first display"
              description={`Open up the “Wave” app on your display and fill in the claiming code to add the display to this customer.${
                hasDisplayClaimPermission
                  ? ''
                  : ' Ask your organization to grant you necessary permissions to do so.'
              }`}
              padding="0"
            >
              <EmptyViewButton
                label="Claim display"
                onClick={handleClaimDisplay}
                isDisabled={!hasDisplayClaimPermission}
              />
            </EmptyView>
            <Link
              isExternal
              href="https://docs.wave.ppds.com/"
              color="blue.500"
              target="_blank"
              rel="noreferrer"
              display="flex"
              alignItems="center"
            >
              <HelpIcon color="blue.500" width={5} height={5} marginRight={2} />
              Need help?
            </Link>
          </VStack>
        ) : (
          <DisplayTableServerSide
            table={table}
            onGoToDetail={(id) => handleDisplayClicked(id)}
            invalidDisplayIds={extractInvalidDisplayIds(error?.graphQLErrors)}
          />
        )}
      </Box>
      <Box display="flex" marginTop="4">
        <Box flex="1">
          <DisplayTableServerSide.PageSizeSelector
            table={table}
            handleSetPageSize={handleSetPageSize}
            pageSize={pageSize}
          />
        </Box>
        <DisplayTableServerSide.Pagination
          pageIndex={pageIndex}
          totalRowCount={totalRowCount}
          handleSetPageIndex={handleSetPageIndex}
          pageSize={pageSize}
        />
      </Box>
      {
        // Remove once subscriptions are released to everyone: https://inthepocket.atlassian.net/browse/TPVWAVE-1460
      }
      {isSubscriptionsEnabled && hasLiteSubscription ? (
        <UpgradeToWaveEssentialModal
          isOpen={upgradeToWaveEssentialModal.isOpen}
          onClose={upgradeToWaveEssentialModal.onClose}
        />
      ) : (
        <BulkActionModal
          customer={data.customer as BulkActionModal_CustomerFragment}
          displays={table.selectedFlatRows.map(
            (row) => row.original as unknown as BulkActionModal_DisplayFragment,
          )}
          organization={data.organization as BulkActionModal_OrganizationFragment}
          isOpen={bulkActionModal.isOpen}
          onCancel={bulkActionModal.onClose}
          onSuccess={handleBulkActionSucceeded}
        />
      )}

      <SelectClaimModal
        customer={data.customer}
        isOpen={isSelectModalOpen}
        onClose={() => setIsSelectModalOpen(false)}
        onSelectSingle={() => {
          setIsSelectModalOpen(false);
          setIsSingleClaimModalOpen(true);
        }}
        onSuccess={handleCreateSuccess}
      />
      <BulkClaimModal
        customer={data.customer as BulkClaimDisplayModal_CustomerFragment}
        customerSite={data.customer as BulkClaimDisplayModal_CustomerFragment}
        code={bulkClaimCode}
        id={bulkClaimId}
        isOpen={isBulkClaimModalOpen}
        onCancel={bulkClaimModal.onClose}
        onSuccess={handleMultiClaimCodeActivate}
        onBack={handleBackToSelect}
      />
      <ClaimDisplayModal
        customer={data.customer as BulkActionModal_CustomerFragment}
        isOpen={isSingleClaimModalOpen}
        onCancel={handleClose}
        onSuccess={handleClaimDisplaySucceeded}
        onBack={handleBackToSelect}
      />
    </>
  );
}
function DisplayOverviewHeading({ data }: DisplayOverviewHeadingProps) {
  const navigate = useNavigate();
  const { isEnabled: isMultiClaimEnabled } = useFeatureFlag('multiClaim');
  const tableContext = useContext(DisplayTableContext);

  if (isNil(tableContext)) {
    throw new Error('TableContext can only be used within TableContext Provider');
  }

  const { table } = tableContext;

  const handleGoBack = () => {
    navigate('/');
  };

  const hasWaveSubscription = isWaveSubscription(data.customer?.waveSubscription);

  const numberOfDisplays = data.customer?.displays.length ?? 0;

  const shouldShowStatistics = hasWaveSubscription && numberOfDisplays > 0;

  const filters = useFilterOptions(table?.state.filters ?? [], table?.data);

  const handleItemClicked = (item: StatisticsItem) => {
    const itemCanApplyFilter = ['Connected', 'Disconnected', 'Standby'].includes(item.name);

    if (itemCanApplyFilter) {
      table?.setAllFilters([
        {
          id: Columns.Site,
          value: [],
        },
        {
          id: Columns.Groups,
          value: [],
        },
        {
          id: Columns.Firmware,
          value: [],
        },
        {
          id: Columns.Playlist,
          value: [],
        },
        {
          id: Columns.PowerSchedule,
          value: [],
        },
        {
          id: Columns.Warnings,
          value: [],
        },
        {
          id: Columns.Status,
          value: [],
        },
      ]);
      table?.setFilter(
        Columns.Status,
        filters.status.all.filter((f) => f.value === item.name),
      );
    }
  };
  async function handleClaimCodeClicked() {
    navigate(`claimCode`, { state: { canGoBack: true } });
  }
  const claimCodeHistoryButton = (
    <Button variant="solid" colorScheme="blue" onClick={handleClaimCodeClicked}>
      View claim code history
    </Button>
  );
  return (
    <>
      {hasWaveSubscription && isMultiClaimEnabled ? (
        <PageHeading
          floatingButton={<BackButton onClick={handleGoBack} />}
          actions={claimCodeHistoryButton}
        >
          Display overview
        </PageHeading>
      ) : (
        <PageHeading floatingButton={<BackButton onClick={handleGoBack} />}>
          Display overview
        </PageHeading>
      )}

      {shouldShowStatistics && (
        <UsageStatistics customer={data.customer} onItemClicked={handleItemClicked} />
      )}
    </>
  );
}

function DisplayOverview({ data, error, refetch }: CustomerDisplaysOverviewProps) {
  const { customer } = useParams();
  const navigate = useNavigate();
  const bulkClaimModal = useDisclosure();
  const claimDisplayModal = useDisclosure();
  const bulkActionModal = useDisclosure();
  const upgradeToWaveEssentialModal = useDisclosure();
  const analytics = useAnalyticsReporter();
  const { verifyUserPermissions } = useAuth();

  // Remove once subscriptions are released to everyone: https://inthepocket.atlassian.net/browse/TPVWAVE-1460
  const { isEnabled: isSubscriptionsEnabled } = useFeatureFlag('subscriptions');
  const { isEnabled: isMultiClaimEnabled } = useFeatureFlag('multiClaim');
  const displays = data?.customer?.displays ?? [];

  const table = useDisplayTable(displays);

  const tableContext = useContext(DisplayTableContext);

  if (isNil(tableContext)) {
    throw new Error('TableContext can only be used within TableContext Provider');
  }

  const hasWaveSubscription = isWaveSubscription(data.customer?.waveSubscription);
  const [bulkClaimCode, setBulkClaimCode] = useState<string>('');
  const [bulkClaimId, setBulkClaimId] = useState<string>('');

  const { setTable } = tableContext;

  useEffect(() => {
    setTable?.(table);
  }, [table, setTable]);

  const selectedCount = table.selectedFlatRows.length;

  async function handleDisplayClicked(id: string) {
    analytics.track('displayDetail');
    navigate(id);
  }

  async function handleClaimDisplay() {
    analytics.track('displayClaimStart');
    if (hasWaveSubscription && isMultiClaimEnabled) {
      setIsSelectModalOpen(true);
    } else {
      setIsSingleClaimModalOpen(true);
    }
  }
  async function handleClose() {
    setIsSingleClaimModalOpen(false);
  }
  async function handleCreateSuccess({ id, code }: { id: string; code: string }) {
    setIsSelectModalOpen(false);
    setIsBulkClaimModalOpen(true);
    setBulkClaimCode(code);
    setBulkClaimId(id);
  }

  async function handleMultiClaimCodeActivate() {
    setIsBulkClaimModalOpen(false);
  }
  async function handleClaimDisplaySucceeded(display: { id: string }) {
    analytics.track('displayClaimComplete');
    claimDisplayModal.onClose();
    await refetch({ customerHandle: customer });
    navigate(display.id);
  }

  async function handleBulkActionSucceeded() {
    bulkActionModal.onClose();
    await refetch({ customerHandle: customer });
  }

  const extractInvalidDisplayIds = (graphQLErrors: GraphQLErrors | undefined): string[] => {
    if (!graphQLErrors) {
      return [];
    }

    const invalidDisplayIds = graphQLErrors?.map(({ path }) => {
      if (!path) {
        return;
      }

      const [c, d, index] = path;
      if (c === 'customer' && d === 'displays' && isNumber(index)) {
        return data?.customer?.displays[index as number]?.id;
      }
    });

    return uniq(invalidDisplayIds?.filter((id) => isDefined(id))) as string[];
  };

  const openBulkActions = () => {
    // Remove once subscriptions are released to everyone: https://inthepocket.atlassian.net/browse/TPVWAVE-1460
    if (isSubscriptionsEnabled && hasLiteSubscription) {
      upgradeToWaveEssentialModal.onOpen();
      return;
    }

    analytics.track('displayOpenBulkActions');
    bulkActionModal.onOpen();
  };

  const hasLiteSubscription = !isWaveSubscription(data.customer?.waveSubscription);
  const { displaysQuery } = useContext(DisplaysQueryContext);
  const claimCodeParam = displaysQuery.get('claimCode');
  const hasDisplayClaimPermission = verifyUserPermissions([Permission.DisplayClaim]);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [isSingleClaimModalOpen, setIsSingleClaimModalOpen] = useState(false);
  const [isBulkClaimModalOpen, setIsBulkClaimModalOpen] = useState(false);
  const handleBackToSelect = () => {
    setIsSingleClaimModalOpen(false);
    setIsBulkClaimModalOpen(false);
    setIsSelectModalOpen(true);
  };
  return (
    <>
      {displays.length > 0 && (
        <PageActions
          left={
            <>
              {selectedCount > 0 ? (
                <BulkActionButton selectedCount={selectedCount} onClick={openBulkActions} />
              ) : null}
              <DisplayTable.Filter table={table} />
            </>
          }
          right={
            claimCodeParam ? null : (
              <Shield requiredPermissions={[Permission.DisplayClaim]}>
                <Button variant="solid" colorScheme="blue" onClick={handleClaimDisplay}>
                  Claim display
                </Button>
              </Shield>
            )
          }
          searchbar={<DisplayTable.SearchSelector />}
        />
      )}

      <Box
        boxShadow="elevated"
        border="1px solid"
        borderRadius="md"
        borderColor="gray.100"
        overflowX="auto"
        bgColor="white"
      >
        {displays.length === 0 ? (
          <VStack paddingTop={20} paddingBottom={20} spacing={8}>
            <EmptyView
              icon={<EmptyDisplayIllustration />}
              title="Claim your first display"
              description={`Open up the “Wave” app on your display and fill in the claiming code to add the display to this customer.${
                hasDisplayClaimPermission
                  ? ''
                  : ' Ask your organization to grant you necessary permissions to do so.'
              }`}
              padding="0"
            >
              <EmptyViewButton
                label="Claim display"
                onClick={handleClaimDisplay}
                isDisabled={!hasDisplayClaimPermission}
              />
            </EmptyView>
            <Link
              isExternal
              href="https://docs.wave.ppds.com/"
              color="blue.500"
              target="_blank"
              rel="noreferrer"
              display="flex"
              alignItems="center"
            >
              <HelpIcon color="blue.500" width={5} height={5} marginRight={2} />
              Need help?
            </Link>
          </VStack>
        ) : (
          <DisplayTable
            table={table}
            onGoToDetail={(id) => handleDisplayClicked(id)}
            invalidDisplayIds={extractInvalidDisplayIds(error?.graphQLErrors)}
          />
        )}
      </Box>
      <Box display="flex" marginTop="4">
        <Box flex="1">
          <DisplayTable.PageSizeSelector table={table} />
        </Box>
        <DisplayTable.Pagination table={table} />
      </Box>
      {
        // Remove once subscriptions are released to everyone: https://inthepocket.atlassian.net/browse/TPVWAVE-1460
      }
      {isSubscriptionsEnabled && hasLiteSubscription ? (
        <UpgradeToWaveEssentialModal
          isOpen={upgradeToWaveEssentialModal.isOpen}
          onClose={upgradeToWaveEssentialModal.onClose}
        />
      ) : (
        <BulkActionModal
          customer={data.customer as BulkActionModal_CustomerFragment}
          displays={table.selectedFlatRows.map(
            (row) => row.original as unknown as BulkActionModal_DisplayFragment,
          )}
          organization={data.organization as BulkActionModal_OrganizationFragment}
          isOpen={bulkActionModal.isOpen}
          onCancel={bulkActionModal.onClose}
          onSuccess={handleBulkActionSucceeded}
        />
      )}

      <SelectClaimModal
        customer={data.customer}
        isOpen={isSelectModalOpen}
        onClose={() => setIsSelectModalOpen(false)}
        onSelectSingle={() => {
          setIsSelectModalOpen(false);
          setIsSingleClaimModalOpen(true);
        }}
        onSuccess={handleCreateSuccess}
      />
      <BulkClaimModal
        customer={data.customer as BulkClaimDisplayModal_CustomerFragment}
        customerSite={data.customer as BulkClaimDisplayModal_CustomerFragment}
        code={bulkClaimCode}
        id={bulkClaimId}
        isOpen={isBulkClaimModalOpen}
        onCancel={bulkClaimModal.onClose}
        onSuccess={handleMultiClaimCodeActivate}
        onBack={handleBackToSelect}
      />
      <ClaimDisplayModal
        customer={data.customer as BulkActionModal_CustomerFragment}
        isOpen={isSingleClaimModalOpen}
        onCancel={handleClose}
        onSuccess={handleClaimDisplaySucceeded}
        onBack={handleBackToSelect}
      />
    </>
  );
}

const POLL_INTERVAL = 10000;

export function CustomerDisplaysIndexPage() {
  const { customer } = useParams();
  const { isEnabled: isServerSidePaginationEnabled } = useFeatureFlag('serverSidePagination');
  const { displaysQuery } = useContext(DisplaysQueryContext);
  const claimCodeParam = displaysQuery.get('claimCode');
  const [pageSize, setPageSize] = useState(25);
  const [pageIndex, setPageIndex] = useState(0);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<DisplayFilters>([
    //starts here
    { id: 'firmware', value: [] },
    { id: 'powerSchedule', value: [] },
    { id: 'playlist', value: [] },
    { id: 'groups', value: [] },
    { id: 'warnings', value: [] },
    { id: 'site', value: [] },
    { id: 'status', value: [] },
    { id: 'multiClaimIds', value: [] },
  ]);
  interface TransformedResult {
    firmwares: string[];
    groupIds: string[];
    playlistIds: string[];
    scheduleIds: string[];
    siteIds: string[];
    status: string[];
    warnings: string[];
    multiClaimIds: string[];
  }
  // Transform function with proper types
  function transformFiltersData(filters: DisplayFilters): TransformedResult {
    const transformed: TransformedResult = {
      firmwares: [],
      groupIds: [],
      playlistIds: [],
      scheduleIds: [],
      siteIds: [],
      status: [],
      warnings: [],
      multiClaimIds: [],
    };
    if (claimCodeParam) {
      const id = claimCodeParam.split('|')[2];
      transformed.multiClaimIds.push(id);
    }
    filters.forEach((filter) => {
      switch (filter.id) {
        case 'firmware':
          transformed.firmwares = filter.value.map((option) => option.id);
          break;
        case 'groups':
          transformed.groupIds = filter.value.map((option) => option.id);
          break;
        case 'playlist':
          transformed.playlistIds = filter.value.map((option) => option.id);
          break;
        case 'powerSchedule':
          transformed.scheduleIds = filter.value.map((option) => option.id);
          break;
        case 'site':
          transformed.siteIds = filter.value.map((option) => option.id);
          break;
        case 'warnings':
          transformed.warnings = filter.value.map((option) => option.id);
          break;
        case 'status':
          transformed.status = filter.value.map((option) => option.id);
          break;
        default:
          break;
      }
    });

    return transformed;
  }
  const transformedData = transformFiltersData(filters);
  const handleSetPageSize = (pageSize: number) => {
    setPageSize(pageSize);
  };
  const handleSetPageIndex = (pageIndex: number) => {
    setPageIndex(pageIndex);
  };

  const handleSetFilters = (filterId: string, newValue: FilterOptionServerSide[]) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) =>
        filter.id === filterId ? { ...filter, value: newValue } : filter,
      ),
    );
  };

  // ---start--- this code is for persisiting the filter via browser URL using useDisplaysQuery.tsx ------

  // const filtersFromUrl = getFiltersFromUrl();
  // const transformFiltersToPersistFormat = (
  //   filtersFromUrl: TransformedResult
  // ): DisplayFilters => {
  //   const createFilterOption = (
  //     column: string,
  //     values: string[]
  //   ): FilterOptionServerSide[] =>
  //     values.map((value) => ({
  //       column: column as FilterKeys,
  //       label: value,
  //       value: value,
  //       id: value
  //     }));

  //   return [
  //     { id: 'firmware', value: createFilterOption('firmware', filtersFromUrl.firmwares) },
  //     { id: 'powerSchedule', value: createFilterOption('powerSchedule', filtersFromUrl.scheduleIds) },
  //     { id: 'playlist', value: createFilterOption('playlist', filtersFromUrl.playlistIds) },
  //     { id: 'groups', value: createFilterOption('groups', filtersFromUrl.groupIds) },
  //     { id: 'warnings', value: createFilterOption('warnings', filtersFromUrl.warnings) },
  //     { id: 'site', value: createFilterOption('site', filtersFromUrl.siteIds) },
  //     { id: 'status', value: createFilterOption('status', filtersFromUrl.status) }
  //   ];
  // };
  // const hasUrlFilters = Object.values(filtersFromUrl).some((array) => array.length > 0);
  // const hasFilters = filters.some((filter) => filter.value.length > 0);
  // const { persistFilters} =  useDisplaysQuery();
  // useEffect(() => {
  //   if(hasUrlFilters){
  //     const transformedFilters = transformFiltersToPersistFormat(filtersFromUrl);

  //       persistFilters(transformedFilters);
  //   }else if(hasFilters){
  //       persistFilters(filters);
  //   }

  // }, [filters]);
  // ----end--- this code is for persisiting the filter via browser URL using useDisplaysQuery.tsx ------

  const handleSetSearch = (searchText: string) => {
    setSearch(searchText);
  };

  const resetFilters = () => {
    setFilters([
      { id: 'firmware', value: [] },
      { id: 'powerSchedule', value: [] },
      { id: 'playlist', value: [] },
      { id: 'groups', value: [] },
      { id: 'warnings', value: [] },
      { id: 'site', value: [] },
      { id: 'status', value: [] },
    ]);
  };

  const { data, loading, error, refetch, startPolling, stopPolling } =
    useCustomerDisplaysIndexPageQuery({
      variables: isServerSidePaginationEnabled
        ? {
            customerHandle: ensure(customer),
            pageNumber: pageIndex,
            pageSize: pageSize,
            searchTerm: search,
            filter: transformedData,
          }
        : {
            customerHandle: ensure(customer),
          },
      pollInterval: POLL_INTERVAL,
      errorPolicy: 'all',
    });

  useConditionalPolling({ startPolling, stopPolling, pollInterval: POLL_INTERVAL });
  const totalRowCount = data?.customer?.totalFilteredDisplaysCount || 0;
  const totalDisplays =
    transformedData?.warnings?.length > 0
      ? data?.customer?.displays?.length || 0
      : data?.customer?.totalDisplaysCount || 0;
  return (
    <Page title="Displays" pageName="displays_overview">
      <DisplayTableProvider>
        {!loading && !error && (
          <PageContent backgroundColor="gray.50">
            {!isServerSidePaginationEnabled ? (
              <DisplayOverviewHeading data={data as DataWithCustomer} />
            ) : (
              <DisplayOverviewHeadingServerSide
                data={data as DataWithCustomer}
                filtersState={filters}
                handleSetFilters={handleSetFilters}
                resetFilters={resetFilters}
              />
            )}
          </PageContent>
        )}
        <PageContent>
          {loading ? (
            <PageLoader />
          ) : error ? (
            <HandleApiError error={error} />
          ) : (
            isDefined(data?.customer) &&
            (isServerSidePaginationEnabled &&
            filters.find((f) => f.id === 'warnings')?.value.length === 0 ? (
              <DisplayOverviewServerSide
                data={data as DataWithCustomer}
                error={error}
                refetch={refetch}
                pageSize={pageSize}
                pageIndex={pageIndex}
                totalRowCount={totalRowCount}
                totalDisplays={totalDisplays}
                filters={filters}
                search={search}
                handleSetSearch={handleSetSearch}
                handleSetPageSize={handleSetPageSize}
                handleSetPageIndex={handleSetPageIndex}
                handleSetFilters={handleSetFilters}
              />
            ) : (
              <DisplayOverview data={data as DataWithCustomer} error={error} refetch={refetch} />
            ))
          )}
        </PageContent>
      </DisplayTableProvider>
    </Page>
  );
}

CustomerDisplaysIndexPage.graphql = {
  queries: {
    CustomerDisplaysIndexPage: gql`
      query CustomerDisplaysIndexPage(
        $customerHandle: String!
        $pageNumber: Int
        $pageSize: Int
        $searchTerm: String
        $filter: DisplayFilters
      ) {
        customer: customerByHandle(handle: $customerHandle) {
          id
          name
          ...ClaimDisplayModal_customer
          ...BulkActionModal_customer
          displays(
            pageNumber: $pageNumber
            pageSize: $pageSize
            searchTerm: $searchTerm
            filter: $filter
          ) {
            id
            serialNumber
            agentVersion
            networkInformation {
              ethernetMacAddress
              wifiMacAddress
              localIPAddress
            }
            ...DisplayTable_display
            ...BulkActionModal_display
          }
          totalDisplaysCount
          totalFilteredDisplaysCount(
            pageNumber: $pageNumber
            pageSize: $pageSize
            searchTerm: $searchTerm
            filter: $filter
          )
          waveSubscription {
            usage {
              current
              max
            }
          }
          displayUsage {
            presence {
              connected
              disconnected
            }
            powerState {
              plannedOn
              plannedStandBy
              unplannedOn
              unplannedStandBy
              disconnected
            }
            contentSource {
              standby
              disconnected
              sources {
                source
                count
              }
            }
          }
          displayFirmwareVersions
        }
        organization {
          ...BulkActionModal_organization
        }
      }
    `,
  },
};
