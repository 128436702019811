import { gql } from '@apollo/client';
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { MaybePromise } from '~utils/types';
import { ClaimDisplayModal_CustomerFragment } from './__generated__/ClaimDisplayModal.graphql';
import { StepClaim_DisplayFragment } from './__generated__/StepClaim.graphql';
import { Wizard } from './Wizard';
import { WizardProvider } from './WizardContext';

interface Props {
  customer: ClaimDisplayModal_CustomerFragment;
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: (display: StepClaim_DisplayFragment) => MaybePromise<void>;
  onBack: () => void;
}

export function ClaimDisplayModal({ customer, isOpen, onCancel, onSuccess, onBack }: Props) {
  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={onCancel}
      closeOnOverlayClick={false}
      closeOnEsc={true}
    >
      <ModalOverlay />
      <ModalContent>
        <WizardProvider>
          <Wizard customer={customer} onCancel={onCancel} onSuccess={onSuccess} onBack={onBack} />
        </WizardProvider>
      </ModalContent>
    </Modal>
  );
}

ClaimDisplayModal.graphql = {
  fragments: {
    ClaimDisplayModal_customer: gql`
      fragment ClaimDisplayModal_customer on Customer {
        id
        ...Wizard_customer
      }
    `,
  },
};
