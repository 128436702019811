import { TableInstance } from 'react-table';
import { PageSizeSelector } from '~components/ui/PageSizeSelector';
import { Pagination } from '~components/ui/Pagination';
import { ClaimCodeTable_ClaimCodeFragment } from './__generated__/ClaimCodeTable.graphql';

export function ClaimCodeTablePagination({
  pageIndex,
  pageSize,
  totalRowCount,
  handleSetPageIndex,
}: {
  pageIndex: number;
  pageSize: number;
  totalRowCount: number;
  handleSetPageIndex: (pageIndex: number) => void;
}) {
  const pageCount = Math.ceil(totalRowCount / pageSize);
  const canPreviousPage = pageIndex > 0;
  const canNextPage = pageIndex < pageCount - 1;
  return (
    <Pagination
      pageCount={pageCount}
      currentPageIndex={pageIndex}
      hasPreviousPage={canPreviousPage}
      hasNextPage={canNextPage}
      goToPage={handleSetPageIndex}
      goToPreviousPage={() => handleSetPageIndex(pageIndex - 1)}
      goToNextPage={() => handleSetPageIndex(pageIndex + 1)}
    />
  );
}
export function ClaimCodeTablePageSizeSelector({
  sizes,
  handleSetPageSize,
  pageSize,
}: {
  table: TableInstance<ClaimCodeTable_ClaimCodeFragment>;
  sizes?: number[];
  pageSize: number;
  handleSetPageSize: (pageSize: number) => void;
}) {
  return (
    <PageSizeSelector
      label="displays per page"
      onChange={(pageSize) => handleSetPageSize(pageSize)}
      options={sizes}
      value={pageSize}
    />
  );
}
