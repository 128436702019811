/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type GroupsCell_ClaimCodeFragment = { __typename: 'MultiClaimCodeDocument', id: string, groups?: Array<string> | null };

export const GroupsCell_ClaimCodeFragmentDoc = gql`
    fragment GroupsCell_claimCode on MultiClaimCodeDocument {
  id
  groups
}
    `;