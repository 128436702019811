/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { CodeCell_ClaimCodeFragmentDoc } from '../Cell/__generated__/CodeCell.graphql';
import { CodeNameCell_ClaimCodeFragmentDoc } from '../Cell/__generated__/CodeNameCell.graphql';
import { ExpiryOnCell_ClaimCodeFragmentDoc } from '../Cell/__generated__/ExpiryOnCell.graphql';
import { GroupsCell_ClaimCodeFragmentDoc } from '../Cell/__generated__/GroupsCell.graphql';
import { LocationCell_ClaimCodeFragmentDoc } from '../Cell/__generated__/LocationCell.graphql';
import { PrefixCell_ClaimCodeFragmentDoc } from '../Cell/__generated__/PrefixCell.graphql';
import { ValidityCell_ClaimCodeFragmentDoc } from '../Cell/__generated__/ValidityCell.graphql';
export type ClaimCodeTable_ClaimCodeFragment = { __typename: 'MultiClaimCodeDocument', id: string, code: string, name?: string | null, expiry?: any | null, groups?: Array<string> | null, site?: string | null, prefix?: string | null };

export type CustomerClaimCodeTable_ClaimCodeFragment = { __typename: 'Customer', id: string, groups: Array<{ __typename: 'Group', id: string, name: string }>, sites: Array<{ __typename: 'Site', name: string, id: string }> };

export const ClaimCodeTable_ClaimCodeFragmentDoc = gql`
    fragment ClaimCodeTable_claimCode on MultiClaimCodeDocument {
  id
  ...CodeCell_claimCode
  ...CodeNameCell_claimCode
  ...ExpiryOnCell_claimCode
  ...GroupsCell_claimCode
  ...LocationCell_claimCode
  ...PrefixCell_claimCode
  ...ValidityCell_claimCode
}
    ${CodeCell_ClaimCodeFragmentDoc}
${CodeNameCell_ClaimCodeFragmentDoc}
${ExpiryOnCell_ClaimCodeFragmentDoc}
${GroupsCell_ClaimCodeFragmentDoc}
${LocationCell_ClaimCodeFragmentDoc}
${PrefixCell_ClaimCodeFragmentDoc}
${ValidityCell_ClaimCodeFragmentDoc}`;
export const CustomerClaimCodeTable_ClaimCodeFragmentDoc = gql`
    fragment CustomerClaimCodeTable_claimCode on Customer {
  id
  groups {
    id
    name
  }
  sites {
    name
    id
  }
}
    `;