import { wrapUseRoutes } from '@sentry/react';
import { Permission } from '@tp-vision/roles-permissions';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import { protect } from '~auth/Protected';
import { useAuth } from '~auth/useAuth';
import { DisplayDetailSettings } from '~components/displays/DisplayDetail/DisplayDetailSettings';
import { DisplayDetailSummary } from '~components/displays/DisplayDetail/summary/DisplayDetailSummary';
import { ApiKeysSettings } from '~components/settings/ApiKeysSettings';
import { OrganizationLogoSettingsPage } from '~components/settings/LogoSettings';
import { NavbarLayout } from '~pages/[organization]/_layout';
import { CustomerDisplayDetailPage } from '~pages/[organization]/customers/[handle]/displays/[id]';
import { DisplayNotFoundPage } from '~pages/[organization]/customers/[handle]/displays/404';
import { ClaimCodePage } from '~pages/[organization]/customers/[handle]/displays/claimCode';
import { CustomerDisplaysIndexPage } from '~pages/[organization]/customers/[handle]/displays/Index';
import { CustomerPlaylistsPage } from '~pages/[organization]/customers/[handle]/playlists';
import { PlaylistDetailsPage } from '~pages/[organization]/customers/[handle]/playlists/[id]';
import { PlaylistNotFoundPage } from '~pages/[organization]/customers/[handle]/playlists/404';
import { CustomerPowerSchedulesPage } from '~pages/[organization]/customers/[handle]/powerSchedules';
import { PowerScheduleDetailsPage } from '~pages/[organization]/customers/[handle]/powerSchedules/[id]';
import { PowerScheduleNotFoundPage } from '~pages/[organization]/customers/[handle]/powerSchedules/404';
import { CustomerSitesPage } from '~pages/[organization]/customers/[handle]/Sites';
import { CustomerNotFoundPage } from '~pages/[organization]/customers/404';
import { CustomersIndexPage } from '~pages/[organization]/customers/Index';
import { OrganizationAppsSettingsPage } from '~pages/[organization]/settings/Apps';
import { OrganizationCustomerEditPage } from '~pages/[organization]/settings/customers/[id]';
import { OrganizationCustomersCreatePage } from '~pages/[organization]/settings/customers/Create';
import { OrganizationCustomersIndexPage } from '~pages/[organization]/settings/customers/Index';
import { OrganizationSettingsIndexPage } from '~pages/[organization]/settings/Index';
import { OrganizationSubscriptionEditPage } from '~pages/[organization]/settings/subscriptions/[id]';
import { OrganizationSubscriptionsIndexPage } from '~pages/[organization]/settings/subscriptions/Index';
import { OrganizationUserEditPage } from '~pages/[organization]/settings/users/[id]';
import { OrganizationUserCreatePage } from '~pages/[organization]/settings/users/Create';
import { OrganizationUsersIndexPage } from '~pages/[organization]/settings/users/Index';
import { ForbiddenPage } from '~pages/403';
import { ConsentPage } from '~pages/legal/Consent';
import { MasterServicesAgreementPage } from '~pages/legal/MasterServicesAgreement';
import { PrivacyPolicyPage } from '~pages/legal/PrivacyPolicy';
import { ServiceSchedulePage } from '~pages/legal/ServiceSchedule';
import { TermsOfUsePage } from '~pages/legal/TermsOfUse';
import { LoginPage } from '~pages/Login';
import { LogoutPage } from '~pages/Logout';
import { isDefined } from '~utils/types';

const useSentryRoutes = wrapUseRoutes(useRoutes);

export function AppRoutes() {
  const { organization, verifyUserPermissions } = useAuth();

  const element = useSentryRoutes([
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/logout',
      element: <LogoutPage />,
    },
    {
      path: '/403',
      element: <ForbiddenPage />,
    },
    {
      path: '/legal',
      children: [
        {
          path: 'consent',
          element: <ConsentPage />,
        },
        {
          path: 'terms-of-use',
          element: <TermsOfUsePage />,
        },
        {
          path: 'privacy-policy',
          element: <PrivacyPolicyPage />,
        },
        {
          path: 'master-services-agreement',
          element: <MasterServicesAgreementPage />,
        },
        {
          path: 'service-schedule',
          element: <ServiceSchedulePage />,
        },
      ],
    },
    {
      path: '*',
      element: protect(<Outlet />),
      children: organization
        ? [
            {
              path: '',
              element: <Navigate to={`/${organization.handle}`} replace />,
            },
            {
              path: organization.handle,
              element: <NavbarLayout hideNavigationLinks />,
              children: [
                {
                  path: '',
                  element: <Navigate to="customers" replace />,
                },
                {
                  path: 'settings',
                  element: <OrganizationSettingsIndexPage />,
                  children: [
                    verifyUserPermissions([Permission.OrganizationUpdate])
                      ? {
                          path: 'logo',
                          element: <OrganizationLogoSettingsPage />,
                        }
                      : null,

                    verifyUserPermissions([Permission.UserView])
                      ? {
                          path: 'users',
                          element: <OrganizationUsersIndexPage />,
                        }
                      : null,
                    verifyUserPermissions([Permission.UserCreate])
                      ? {
                          path: 'users/create',
                          element: <OrganizationUserCreatePage />,
                        }
                      : null,
                    verifyUserPermissions([Permission.UserView])
                      ? {
                          path: 'users/:userId',
                          element: <OrganizationUserEditPage />,
                        }
                      : null,
                    verifyUserPermissions([Permission.CustomerView])
                      ? {
                          path: 'customers',
                          element: <OrganizationCustomersIndexPage />,
                        }
                      : null,
                    verifyUserPermissions([Permission.CustomerView])
                      ? {
                          path: 'customers/create',
                          element: <OrganizationCustomersCreatePage />,
                        }
                      : null,
                    verifyUserPermissions([Permission.CustomerView])
                      ? {
                          path: 'customers/:customerId',
                          element: <OrganizationCustomerEditPage />,
                        }
                      : null,
                    {
                      path: 'subscriptions',
                      element: <OrganizationSubscriptionsIndexPage />,
                    },
                    {
                      path: 'subscriptions/:subscriptionId',
                      element: <OrganizationSubscriptionEditPage />,
                    },
                    {
                      path: 'apps',
                      element: <OrganizationAppsSettingsPage />,
                    },
                    verifyUserPermissions([Permission.ApiKeyView])
                      ? {
                          path: 'api-keys',
                          element: <ApiKeysSettings />,
                        }
                      : null,
                  ].filter(isDefined),
                },
                {
                  path: 'customers',
                  element: <CustomersIndexPage />,
                },
                {
                  path: 'customers/404',
                  element: <CustomerNotFoundPage />,
                },
              ],
            },
            {
              path: `${organization.handle}/customers/:customer`,
              element: <NavbarLayout />,
              children: [
                {
                  path: '',
                  element: <Navigate to="displays" replace />,
                },
                {
                  path: 'displays',
                  element: <CustomerDisplaysIndexPage />,
                },
                {
                  path: 'displays/claimCode',
                  element: <ClaimCodePage />,
                },
                {
                  path: 'displays/404',
                  element: <DisplayNotFoundPage />,
                },
                {
                  path: 'displays/:displayId',
                  element: <CustomerDisplayDetailPage />,
                  children: [
                    {
                      path: 'summary',
                      element: <DisplayDetailSummary />,
                    },
                    {
                      path: 'settings',
                      element: <DisplayDetailSettings />,
                    },
                  ],
                },
                {
                  path: 'sites',
                  element: <CustomerSitesPage />,
                },
                {
                  path: 'playlists',
                  element: <CustomerPlaylistsPage />,
                },
                {
                  path: 'playlists/404',
                  element: <PlaylistNotFoundPage />,
                },
                {
                  path: 'playlists/:playlistId',
                  element: <PlaylistDetailsPage />,
                },
                {
                  path: 'schedules',
                  element: <CustomerPowerSchedulesPage />,
                },
                {
                  path: 'schedules/404',
                  element: <PowerScheduleNotFoundPage />,
                },
                {
                  path: 'schedules/:powerScheduleId',
                  element: <PowerScheduleDetailsPage />,
                },
              ],
            },
            {
              // This wildcard match fixes two problems at once:
              //  - any incorrect link will take you back to the organization dashboard.
              //  - any link that references an organization you aren't part of will auto-correct to your organization.
              path: '*',
              element: <Navigate to={`/${organization.handle}`} replace />,
            },
          ]
        : [],
    },
  ]);

  return element;
}
