import { ExtractFeatures, fromEnv, Keat } from 'keat';
import { ReactNode, useMemo } from 'react';
import { useAuth } from '~auth/useAuth';

declare module 'keat' {
  interface KeatNode {
    user: {
      email?: string;
      roles?: string[];
      permissions?: string[];
      organization?: string;
    };
  }
}

export type Feature = ExtractFeatures<typeof keat>;

export const keat = Keat.create({
  audiences: {
    staff: (user) =>
      (user?.roles?.includes('Staff') || user?.permissions?.includes('feature:staff')) ?? false,
    stakeholders: (user) =>
      (user?.roles?.includes('Stakeholder') ||
        user?.permissions?.includes('feature:stakeholder')) ??
      false,
    tester: (user) =>
      (user?.roles?.includes('Tester') || user?.permissions?.includes('feature:tester')) ?? false,
    tpvision: (user) => user?.organization === 'ce42e167-c6b5-4105-89f0-8290f3f559b2',
    developer: (user) => user?.roles?.includes('Developer') ?? false,
  },
  features: {
    agentReleaseChannel: fromEnv(process.env.REACT_APP_ENABLE_AGENT_RELEASE_CHANNEL_TO, 'staff'),
    appDirectMarketplaceLink: fromEnv(
      process.env.REACT_APP_ENABLE_APPDIRECT_MARKETPLACE_LINK,
      'staff',
    ),
    brightness: fromEnv(process.env.REACT_APP_ENABLE_BRIGHTNESS_TO, 'staff'),
    subscriptions: fromEnv(process.env.REACT_APP_ENABLE_SUBSCRIPTIONS_TO, 'staff'),
    multiClaim: fromEnv(process.env.REACT_APP_ENABLE_MULTI_CLAIM_TO, 'staff'),
    playlistTypes: fromEnv(process.env.REACT_APP_ENABLE_PLAYLIST_TYPES_TO, 'staff'),
    serverSidePagination: fromEnv(process.env.REACT_APP_ENABLE_SERVER_SIDE_PAGINATION_TO, 'staff'),
  },
});

export function useFeatureFlag(feature: Feature) {
  const auth = useAuth();

  return useMemo(
    () => ({
      isEnabled: keat.isEnabled(feature, {
        email: auth.user.email,
        roles: auth.user['https://keat.io/audiences'],
        organization: auth.organization?.id ?? undefined,
        permissions: auth.user.permissions,
      }),
    }),
    [feature, auth],
  );
}

interface Props {
  feature: Feature;
  children: ReactNode;
}

export function FeatureFlag({ feature, children }: Props) {
  const { isEnabled } = useFeatureFlag(feature);

  if (!isEnabled) {
    return null;
  }

  return <>{children}</>;
}
