export enum Columns {
  Alias = 'alias',
  Site = 'site',
  Firmware = 'firmware',
  Groups = 'groups',
  ContentSource = 'contentSource',
  Power = 'power',
  Playlist = 'playlist',
  PowerSchedule = 'powerSchedule',
  Selection = 'selection',
  Status = 'status',
  Volume = 'volume',
  Warnings = 'warnings',
  ClaimCode = 'claimCode',
}

export type FilterKeys =
  | Columns.Firmware
  | Columns.Groups
  | Columns.Playlist
  | Columns.PowerSchedule
  | Columns.Site
  | Columns.Warnings
  | Columns.Status
  | Columns.ClaimCode;

export const FilterKeys = [
  Columns.Firmware,
  Columns.Groups,
  Columns.Playlist,
  Columns.PowerSchedule,
  Columns.Site,
  Columns.Warnings,
  Columns.Status,
  Columns.ClaimCode,
] as const;

export type FilterOption = {
  column: FilterKeys;
  label: string;
  value: string;
};

export type FilterOptions = {
  [key in FilterKeys]: { all: FilterOption[]; enabled: FilterOption[] };
};

export const MINUTES_IN_A_DAY = 1440;

export type FilterOptionServerSide = {
  column: FilterKeys;
  label: string;
  value: string;
  id: string;
};

export type FilterOptionsServerSide = {
  [key in FilterKeys]: { all: FilterOptionServerSide[]; enabled: FilterOptionServerSide[] };
};
