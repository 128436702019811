import { gql } from '@apollo/client';
import { chakra, Tag } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MultiValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { components, SelectOption } from '~components/ui/Select';
import { MaybePromise } from '~utils/types';
import {
  BulkClaimDisplayGroupsSelect_CustomerFragment,
  BulkClaimDisplayGroupsSelect_GroupFragment,
} from './__generated__/BulkClaimDisplayGroupSelect.graphql';

interface Props {
  tabIndex?: number;
  customer: BulkClaimDisplayGroupsSelect_CustomerFragment;
  value: BulkClaimDisplayGroupsSelect_GroupFragment[];
  isLoading: boolean;
  onCreate: (
    customer: BulkClaimDisplayGroupsSelect_CustomerFragment,
    name: string,
  ) => MaybePromise<void>;
  onChange: (groups: BulkClaimDisplayGroupsSelect_GroupFragment[]) => void;
  onGroupCreated: BulkClaimDisplayGroupsSelect_GroupFragment[];
  resetOnGroupCreated: () => void;
}

export function BulkClaimDisplayGroupsSelect({
  tabIndex,
  customer,
  isLoading,
  onCreate,
  onChange,
  onGroupCreated,
  resetOnGroupCreated,
}: Props) {
  const availableGroups = useMemo(
    () =>
      customer.groups.map<SelectOption>((group) => ({
        label: group.name,
        value: group.id,
      })),
    [customer],
  );

  const [currentOption, setCurrentOption] = useState<SelectOption[]>([]);
  useEffect(() => {
    if (onGroupCreated && onGroupCreated.length > 0) {
      const newGroup = onGroupCreated[0];
      const newOption = { label: newGroup.name, value: newGroup.id };
      setCurrentOption((prev) => {
        const updatedOptions = [...prev, newOption];
        const selectedGroups = updatedOptions.map((option) => ({
          id: option.value,
          name: option.label,
          __typename: 'Group',
        })) as BulkClaimDisplayGroupsSelect_GroupFragment[];
        onChange(selectedGroups);
        return updatedOptions;
      });
      resetOnGroupCreated();
    }
  }, [onChange, onGroupCreated, resetOnGroupCreated]);

  const handleCreate = useCallback(
    (name: string) => {
      onCreate(customer, name);
    },
    [onCreate, customer],
  );
  const handleChange = useCallback(
    (_value: MultiValue<SelectOption>) => {
      const selectedGroups = _value.map((option) => ({
        id: option.value,
        name: option.label,
        __typename: 'Group',
      })) as BulkClaimDisplayGroupsSelect_GroupFragment[];
      setCurrentOption([..._value]);
      onChange(selectedGroups);
    },
    [onChange],
  );

  return (
    <CreatableSelect
      tabIndex={tabIndex}
      isMulti={true}
      components={components}
      formatCreateLabel={(value) => (
        <>
          <chakra.span fontWeight="bold" fontSize="md">
            Create
          </chakra.span>
          <Tag
            colorScheme="gray"
            variant="outline"
            display="inline"
            marginLeft="2"
            verticalAlign="middle"
            fontSize="xs"
            paddingY="1"
            paddingX="2"
          >
            {value}
          </Tag>
        </>
      )}
      styles={{
        input: (base) => ({
          ...base,
          margin: 0,
          marginLeft: '0.5rem',
        }),
      }}
      isLoading={isLoading}
      onCreateOption={handleCreate}
      onChange={handleChange}
      options={availableGroups}
      value={currentOption}
      placeholder="Select or create a group"
      isClearable={false}
      menuPlacement="auto"
    />
  );
}

BulkClaimDisplayGroupsSelect.graphql = {
  fragments: {
    BulkClaimDisplayGroupsSelect_customer: gql`
      fragment BulkClaimDisplayGroupsSelect_customer on Customer {
        id
        groups {
          id
          name
        }
      }
    `,

    BulkClaimDisplayGroupsSelect_group: gql`
      fragment BulkClaimDisplayGroupsSelect_group on Group {
        id
        name
      }
    `,
  },
};
