import {
  Accordion,
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { Row, TableInstance } from 'react-table';
import { FilterOption } from '~components/displays/ClaimCodeTable/constants';
import { SelectOption } from '~components/ui/Select';
import {
  DataWithGroupSite,
  MultiClaimFilters,
} from '~pages/[organization]/customers/[handle]/displays/claimCode';
import { ClaimCodeTable_ClaimCodeFragment } from '../__generated__/ClaimCodeTable.graphql';
import { FilterButton } from './FilterButton';
import { FilterSection } from './FilterSection';
import { FilterTagList } from './FilterTags';
import { useFilterOptions } from './useFilterOptions';

type Props = {
  table: TableInstance<ClaimCodeTable_ClaimCodeFragment>;
  data: DataWithGroupSite;
  filtersState: MultiClaimFilters;
  handleSetFilters: (filterId: string, newValue: FilterOption[]) => void;
};

export function Filter({ data, filtersState, handleSetFilters }: Props) {
  const filters = useFilterOptions(filtersState, data);
  const allEnabledFilters = [...filters.groups.enabled, ...filters.site.enabled];

  const toggleFilter = useCallback(
    (filter: FilterOption) => {
      const currentFilter = filtersState.find((f) => f.id === filter.column);
      const isEnabled = currentFilter?.value.some((v) => v.value === filter.value);

      if (isEnabled) {
        handleSetFilters(
          filter.column,
          currentFilter?.value.filter((v) => v.value !== filter.value) ?? [],
        );
      } else {
        handleSetFilters(filter.column, [...(currentFilter?.value ?? []), filter]);
      }
    },
    [filtersState, handleSetFilters],
  );

  const disableFilter = useCallback(
    (filter: FilterOption) => {
      const currentFilter = filtersState.find((f) => f.id === filter.column);
      handleSetFilters(
        filter.column,
        currentFilter?.value.filter((v) => v.value !== filter.value) ?? [],
      );
    },
    [filtersState, handleSetFilters],
  );

  const resetAllFilters = useCallback(() => {
    filtersState.forEach((filter) => handleSetFilters(filter.id, []));
  }, [filtersState, handleSetFilters]);

  return (
    <Box display="flex" alignItems="center">
      <Popover closeOnBlur closeOnEsc placement="bottom-start" isLazy>
        <PopoverTrigger>
          <FilterButton enabledFilterCount={allEnabledFilters.length} />
        </PopoverTrigger>

        <PopoverContent color="gray.900" width="430px">
          <PopoverBody>
            <Accordion allowToggle>
              <FilterSection name="groups" filters={filters.groups} onToggle={toggleFilter} />
              <FilterSection name="sites" filters={filters.site} onToggle={toggleFilter} />
            </Accordion>
          </PopoverBody>
        </PopoverContent>
      </Popover>

      <Box marginLeft="4">
        <FilterTagList
          filters={allEnabledFilters}
          onDisable={disableFilter}
          onReset={resetAllFilters}
        />
      </Box>
    </Box>
  );
}

export function filterBySite(
  rows: Array<Row<ClaimCodeTable_ClaimCodeFragment>>,
  columnIds: string[],
  filterValues: SelectOption[],
) {
  return filterValues.length
    ? rows.filter((row) => {
        return filterValues.some((filter) => {
          return filter.value === row.original.site;
        });
      })
    : rows;
}

export function filterByGroups(
  rows: Array<Row<ClaimCodeTable_ClaimCodeFragment>>,
  columnIds: string[],
  filterValues: SelectOption[],
) {
  return filterValues.length
    ? rows.filter((row) => {
        return filterValues
          .map((filter) => filter.value)
          .some((id) => {
            return (row.original.groups?.map((group) => group) ?? []).indexOf(id) > -1;
          });
      })
    : rows;
}
