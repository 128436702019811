import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { TableInstance } from 'react-table';
import { ClaimCodeTable_ClaimCodeFragment } from './__generated__/ClaimCodeTable.graphql';

export const ClaimCodeTableContext = createContext<{
  table: TableInstance<ClaimCodeTable_ClaimCodeFragment> | null;
  setTable?: Dispatch<SetStateAction<TableInstance<ClaimCodeTable_ClaimCodeFragment> | null>>;
} | null>(null);

export const ClaimCodeTableProvider = ({ children }: { children: ReactNode }) => {
  const [table, setTable] = useState<TableInstance<ClaimCodeTable_ClaimCodeFragment> | null>(null);

  return (
    <ClaimCodeTableContext.Provider value={{ table, setTable }}>
      {children}
    </ClaimCodeTableContext.Provider>
  );
};
