import { gql } from '@apollo/client';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { isWaveSubscription } from '~utils/subscriptions';
import { MaybePromise } from '~utils/types';
import { StepClaim_DisplayFragment } from './__generated__/StepClaim.graphql';
import { Wizard_CustomerFragment } from './__generated__/Wizard.graphql';
import { StepApplySettings } from './StepApplySettings';
import { StepClaim } from './StepClaim';
import { StepMetadata } from './StepMetadata';
import { StepSubscription } from './StepSubscription';
import { useWizard } from './WizardContext';

interface Props {
  customer: Wizard_CustomerFragment;
  onCancel: () => void;
  onSuccess: (display: StepClaim_DisplayFragment) => MaybePromise<void>;
  onBack: () => void;
}

export function Wizard({ customer, onSuccess, onCancel, onBack }: Props) {
  const { state, completeClaiming } = useWizard();
  const [isClaiming, setIsClaiming] = useState(false);

  const hasLiteSubscription = !isWaveSubscription(customer.waveSubscription);

  const hasAvailableSubscriptionSeats =
    customer.waveSubscription?.usage.max === null ||
    (customer.waveSubscription?.usage.current ?? 0) < (customer.waveSubscription?.usage.max ?? 0);

  const canClaimDisplay = hasLiteSubscription || hasAvailableSubscriptionSeats;

  // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want this to run once
  useEffect(() => setIsClaiming(canClaimDisplay), []);

  /**
   * Show the claiming steps when a display can be claiming for the customer OR when the claiming procedure was started.
   *
   * The `|| isClaiming` part of the check prevents the else-case (`!canClaimDisplay`) from rendering in scenarios where
   * the last "seat" of a WaveSubscription is used.
   */
  return canClaimDisplay || isClaiming ? (
    <AnimatePresence initial={false} mode="wait">
      {state.step.name === 'claim' && (
        <motion.div
          key={state.step.name}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <StepClaim
            customer={customer}
            onCancel={onCancel}
            onSuccess={completeClaiming}
            onBack={onBack}
            hasLiteSubscription={hasLiteSubscription}
          />
        </motion.div>
      )}
      {state.step.name === 'apply_settings' && (
        <motion.div
          key={state.step.name}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <StepApplySettings />
        </motion.div>
      )}
      {state.step.name === 'add_metadata' && (
        <motion.div
          key={state.step.name}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <StepMetadata customer={customer} onSuccess={onSuccess} />
        </motion.div>
      )}
    </AnimatePresence>
  ) : (
    <AnimatePresence initial={false} mode="wait">
      <motion.div
        key={state.step.name}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <StepSubscription onCancel={onCancel} />
      </motion.div>
    </AnimatePresence>
  );
}

Wizard.graphql = {
  fragments: {
    Wizard_customer: gql`
      fragment Wizard_customer on Customer {
        id
        ...StepMetadata_customer
        waveSubscription {
          usage {
            current
            max
          }
        }
      }
    `,
  },
};
