import { createIcon } from '@chakra-ui/react';

export const RevokeIcon = createIcon({
  displayName: 'RevokeIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path
          d="M12 22.25C17.3848 22.25 21.75 17.8848 21.75 12.5C21.75 7.11522 17.3848 2.75 12 2.75C6.61522 2.75 2.25 7.11522 2.25 12.5C2.25 17.8848 6.61522 22.25 12 22.25Z"
          stroke="#243B53"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.10571 5.60571L18.8945 19.3945"
          stroke="#243B53"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  ),
});
