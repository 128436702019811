import { createIcon } from '@chakra-ui/react';

export const BulkDisplayIllustration = createIcon({
  displayName: 'BulkDisplayIllustration',
  viewBox: '0 0 173 173',
  defaultProps: {
    fill: 'none',
    width: '173px',
    height: '173px',
  },
  path: (
    <>
      <circle cx="86.499" cy="86.499" r="86.499" fill="#D9E2EC" />
      <g filter="url(#filter0_dd_10_3317)">
        <path
          d="M43.7295 47.0946C43.7295 44.2637 46.0244 41.9688 48.8554 41.9688H138.558C141.389 41.9688 143.684 44.2637 143.684 47.0946V99.6348C143.684 102.466 141.389 104.761 138.558 104.761H48.8554C46.0244 104.761 43.7295 102.466 43.7295 99.6348V47.0946Z"
          fill="white"
        />
        <path
          d="M69.3588 104.761H119.977V106.042C119.977 107.458 118.829 108.605 117.414 108.605H71.9218C70.5063 108.605 69.3588 107.458 69.3588 106.042V104.761Z"
          fill="white"
        />
        <path
          d="M69.3588 103.479H68.0774H48.8554C46.7322 103.479 45.011 101.758 45.011 99.6348V47.0946C45.011 44.9714 46.7322 43.2502 48.8554 43.2502H138.558C140.681 43.2502 142.402 44.9714 142.402 47.0946V99.6348C142.402 101.758 140.681 103.479 138.558 103.479H121.258H119.977H69.3588ZM70.6403 106.042H118.695C118.695 106.75 118.122 107.324 117.414 107.324H71.9218C71.214 107.324 70.6403 106.75 70.6403 106.042Z"
          stroke="#0F5291"
          strokeWidth="2.56292"
        />
      </g>
      <g filter="url(#filter1_dd_10_3317)">
        <path
          d="M36.5215 54.3017C36.5215 51.4707 38.8164 49.1758 41.6474 49.1758H131.35C134.181 49.1758 136.476 51.4707 136.476 54.3016V106.842C136.476 109.673 134.181 111.968 131.35 111.968H41.6474C38.8164 111.968 36.5215 109.673 36.5215 106.842V54.3017Z"
          fill="white"
        />
        <path
          d="M62.1508 111.968H112.769V113.249C112.769 114.665 111.621 115.812 110.206 115.812H64.7138C63.2983 115.812 62.1508 114.665 62.1508 113.249V111.968Z"
          fill="white"
        />
        <path
          d="M62.1508 110.686H60.8694H41.6474C39.5241 110.686 37.8029 108.965 37.8029 106.842V54.3017C37.8029 52.1784 39.5241 50.4572 41.6474 50.4572H131.35C133.473 50.4572 135.194 52.1784 135.194 54.3016V106.842C135.194 108.965 133.473 110.686 131.35 110.686H114.05H112.769H62.1508ZM63.4323 113.249H111.487C111.487 113.957 110.914 114.531 110.206 114.531H64.7138C64.006 114.531 63.4323 113.957 63.4323 113.249Z"
          stroke="#0F5291"
          strokeWidth="2.56292"
        />
      </g>
      <g filter="url(#filter2_dd_10_3317)">
        <path
          d="M29.3125 61.5106C29.3125 58.6797 31.6074 56.3848 34.4384 56.3848H124.141C126.972 56.3848 129.267 58.6797 129.267 61.5106V114.051C129.267 116.882 126.972 119.177 124.141 119.177H34.4384C31.6074 119.177 29.3125 116.882 29.3125 114.051V61.5106Z"
          fill="white"
        />
        <path
          d="M54.9418 119.177H105.56V120.458C105.56 121.874 104.412 123.021 102.997 123.021H57.5048C56.0893 123.021 54.9418 121.874 54.9418 120.458V119.177Z"
          fill="white"
        />
        <path
          d="M54.9418 117.895H53.6604H34.4384C32.3152 117.895 30.594 116.174 30.594 114.051V61.5106C30.594 59.3874 32.3152 57.6662 34.4384 57.6662H124.141C126.264 57.6662 127.985 59.3874 127.985 61.5106V114.051C127.985 116.174 126.264 117.895 124.141 117.895H106.841H105.56H54.9418ZM56.2233 120.458H104.278C104.278 121.166 103.705 121.74 102.997 121.74H57.5048C56.797 121.74 56.2233 121.166 56.2233 120.458Z"
          stroke="#0F5291"
          strokeWidth="2.56292"
        />
      </g>
      <path
        d="M44.0298 90.4264V90.2407C44.0334 89.6036 44.0898 89.0958 44.1991 88.7172C44.3119 88.3386 44.4757 88.0328 44.6905 87.7998C44.9053 87.5668 45.1638 87.3556 45.466 87.1663C45.6917 87.0207 45.8937 86.8696 46.0721 86.7131C46.2505 86.5565 46.3925 86.3836 46.498 86.1943C46.6036 86.0013 46.6564 85.7866 46.6564 85.5499C46.6564 85.2987 46.5963 85.0785 46.4762 84.8892C46.3561 84.6999 46.1941 84.5542 45.9902 84.4523C45.79 84.3504 45.5679 84.2994 45.324 84.2994C45.0873 84.2994 44.8635 84.3522 44.6523 84.4578C44.4412 84.5597 44.2682 84.7126 44.1335 84.9165C43.9988 85.1167 43.926 85.3661 43.9151 85.6646H41.6871C41.7053 84.9365 41.8801 84.3358 42.2113 83.8625C42.5426 83.3856 42.9813 83.0307 43.5274 82.7977C44.0735 82.5611 44.676 82.4427 45.3349 82.4427C46.0594 82.4427 46.7001 82.5629 47.2571 82.8032C47.8141 83.0398 48.251 83.3838 48.5677 83.8352C48.8844 84.2867 49.0428 84.8309 49.0428 85.468C49.0428 85.8939 48.9718 86.2726 48.8298 86.6038C48.6915 86.9315 48.4967 87.2227 48.2455 87.4776C47.9943 87.7288 47.6976 87.9563 47.3554 88.1602C47.0678 88.3313 46.8312 88.5097 46.6455 88.6953C46.4635 88.881 46.3269 89.0958 46.2359 89.3397C46.1486 89.5836 46.103 89.884 46.0994 90.2407V90.4264H44.0298ZM45.111 93.9213C44.747 93.9213 44.4357 93.7939 44.1772 93.539C43.9224 93.2805 43.7968 92.9711 43.8004 92.6107C43.7968 92.2539 43.9224 91.9481 44.1772 91.6933C44.4357 91.4384 44.747 91.311 45.111 91.311C45.4569 91.311 45.7608 91.4384 46.023 91.6933C46.2851 91.9481 46.418 92.2539 46.4216 92.6107C46.418 92.851 46.3542 93.0712 46.2305 93.2714C46.1103 93.468 45.952 93.6264 45.7554 93.7465C45.5588 93.863 45.344 93.9213 45.111 93.9213Z"
        fill="#0F5291"
      />
      <rect
        x="39.8858"
        y="79.129"
        width="10.8926"
        height="17.3"
        rx="0.961095"
        stroke="#9FB3C8"
        strokeWidth="0.64073"
      />
      <path
        d="M56.8431 90.4264V90.2407C56.8467 89.6036 56.9031 89.0958 57.0124 88.7172C57.1252 88.3386 57.289 88.0328 57.5038 87.7998C57.7186 87.5668 57.9771 87.3556 58.2793 87.1663C58.505 87.0207 58.707 86.8696 58.8854 86.7131C59.0638 86.5565 59.2058 86.3836 59.3113 86.1943C59.4169 86.0013 59.4697 85.7866 59.4697 85.5499C59.4697 85.2987 59.4096 85.0785 59.2895 84.8892C59.1694 84.6999 59.0074 84.5542 58.8035 84.4523C58.6033 84.3504 58.3812 84.2994 58.1373 84.2994C57.9006 84.2994 57.6767 84.3522 57.4656 84.4578C57.2544 84.5597 57.0815 84.7126 56.9468 84.9165C56.8121 85.1167 56.7393 85.3661 56.7284 85.6646H54.5004C54.5186 84.9365 54.6934 84.3358 55.0246 83.8625C55.3559 83.3856 55.7946 83.0307 56.3407 82.7977C56.8868 82.5611 57.4893 82.4427 58.1482 82.4427C58.8727 82.4427 59.5134 82.5629 60.0704 82.8032C60.6274 83.0398 61.0642 83.3838 61.381 83.8352C61.6977 84.2867 61.8561 84.8309 61.8561 85.468C61.8561 85.8939 61.7851 86.2726 61.6431 86.6038C61.5047 86.9315 61.31 87.2227 61.0588 87.4776C60.8076 87.7288 60.5109 87.9563 60.1687 88.1602C59.8811 88.3313 59.6444 88.5097 59.4588 88.6953C59.2768 88.881 59.1402 89.0958 59.0492 89.3397C58.9618 89.5836 58.9163 89.884 58.9127 90.2407V90.4264H56.8431ZM57.9243 93.9213C57.5603 93.9213 57.249 93.7939 56.9905 93.539C56.7357 93.2805 56.6101 92.9711 56.6137 92.6107C56.6101 92.2539 56.7357 91.9481 56.9905 91.6933C57.249 91.4384 57.5603 91.311 57.9243 91.311C58.2702 91.311 58.5741 91.4384 58.8363 91.6933C59.0984 91.9481 59.2312 92.2539 59.2349 92.6107C59.2312 92.851 59.1675 93.0712 59.0438 93.2714C58.9236 93.468 58.7653 93.6264 58.5687 93.7465C58.3721 93.863 58.1573 93.9213 57.9243 93.9213Z"
        fill="#0F5291"
      />
      <rect
        x="52.7001"
        y="79.129"
        width="10.8926"
        height="17.3"
        rx="0.961095"
        stroke="#9FB3C8"
        strokeWidth="0.64073"
      />
      <path
        d="M69.4976 90.4264V90.2407C69.5012 89.6036 69.5576 89.0958 69.6668 88.7172C69.7797 88.3386 69.9435 88.0328 70.1583 87.7998C70.3731 87.5668 70.6316 87.3556 70.9337 87.1663C71.1594 87.0207 71.3615 86.8696 71.5399 86.7131C71.7183 86.5565 71.8602 86.3836 71.9658 86.1943C72.0714 86.0013 72.1242 85.7866 72.1242 85.5499C72.1242 85.2987 72.0641 85.0785 71.944 84.8892C71.8238 84.6999 71.6618 84.5542 71.458 84.4523C71.2577 84.3504 71.0357 84.2994 70.7918 84.2994C70.5551 84.2994 70.3312 84.3522 70.1201 84.4578C69.9089 84.5597 69.736 84.7126 69.6013 84.9165C69.4666 85.1167 69.3938 85.3661 69.3829 85.6646H67.1549C67.1731 84.9365 67.3478 84.3358 67.6791 83.8625C68.0104 83.3856 68.4491 83.0307 68.9952 82.7977C69.5412 82.5611 70.1437 82.4427 70.8027 82.4427C71.5271 82.4427 72.1679 82.5629 72.7249 82.8032C73.2819 83.0398 73.7187 83.3838 74.0354 83.8352C74.3522 84.2867 74.5105 84.8309 74.5105 85.468C74.5105 85.8939 74.4395 86.2726 74.2976 86.6038C74.1592 86.9315 73.9645 87.2227 73.7133 87.4776C73.4621 87.7288 73.1654 87.9563 72.8232 88.1602C72.5356 88.3313 72.2989 88.5097 72.1133 88.6953C71.9312 88.881 71.7947 89.0958 71.7037 89.3397C71.6163 89.5836 71.5708 89.884 71.5672 90.2407V90.4264H69.4976ZM70.5788 93.9213C70.2147 93.9213 69.9035 93.7939 69.645 93.539C69.3902 93.2805 69.2646 92.9711 69.2682 92.6107C69.2646 92.2539 69.3902 91.9481 69.645 91.6933C69.9035 91.4384 70.2147 91.311 70.5788 91.311C70.9246 91.311 71.2286 91.4384 71.4907 91.6933C71.7528 91.9481 71.8857 92.2539 71.8894 92.6107C71.8857 92.851 71.822 93.0712 71.6982 93.2714C71.5781 93.468 71.4197 93.6264 71.2232 93.7465C71.0266 93.863 70.8118 93.9213 70.5788 93.9213Z"
        fill="#0F5291"
      />
      <rect
        x="65.5147"
        y="79.129"
        width="10.8926"
        height="17.3"
        rx="0.961095"
        stroke="#9FB3C8"
        strokeWidth="0.64073"
      />
      <path
        d="M86.3169 90.4264V90.2407C86.3205 89.6036 86.377 89.0958 86.4862 88.7172C86.599 88.3386 86.7629 88.0328 86.9776 87.7998C87.1924 87.5668 87.4509 87.3556 87.7531 87.1663C87.9788 87.0207 88.1808 86.8696 88.3592 86.7131C88.5376 86.5565 88.6796 86.3836 88.7852 86.1943C88.8907 86.0013 88.9435 85.7866 88.9435 85.5499C88.9435 85.2987 88.8834 85.0785 88.7633 84.8892C88.6432 84.6999 88.4812 84.5542 88.2773 84.4523C88.0771 84.3504 87.855 84.2994 87.6111 84.2994C87.3745 84.2994 87.1506 84.3522 86.9394 84.4578C86.7283 84.5597 86.5553 84.7126 86.4206 84.9165C86.2859 85.1167 86.2131 85.3661 86.2022 85.6646H83.9742C83.9924 84.9365 84.1672 84.3358 84.4985 83.8625C84.8297 83.3856 85.2684 83.0307 85.8145 82.7977C86.3606 82.5611 86.9631 82.4427 87.622 82.4427C88.3465 82.4427 88.9872 82.5629 89.5442 82.8032C90.1012 83.0398 90.5381 83.3838 90.8548 83.8352C91.1715 84.2867 91.3299 84.8309 91.3299 85.468C91.3299 85.8939 91.2589 86.2726 91.1169 86.6038C90.9786 86.9315 90.7838 87.2227 90.5326 87.4776C90.2814 87.7288 89.9847 87.9563 89.6425 88.1602C89.3549 88.3313 89.1183 88.5097 88.9326 88.6953C88.7506 88.881 88.6141 89.0958 88.523 89.3397C88.4357 89.5836 88.3902 89.884 88.3865 90.2407V90.4264H86.3169ZM87.3981 93.9213C87.0341 93.9213 86.7228 93.7939 86.4643 93.539C86.2095 93.2805 86.0839 92.9711 86.0875 92.6107C86.0839 92.2539 86.2095 91.9481 86.4643 91.6933C86.7228 91.4384 87.0341 91.311 87.3981 91.311C87.744 91.311 88.048 91.4384 88.3101 91.6933C88.5722 91.9481 88.7051 92.2539 88.7087 92.6107C88.7051 92.851 88.6414 93.0712 88.5176 93.2714C88.3974 93.468 88.2391 93.6264 88.0425 93.7465C87.8459 93.863 87.6311 93.9213 87.3981 93.9213Z"
        fill="#0F5291"
      />
      <rect
        x="82.1739"
        y="79.129"
        width="10.8926"
        height="17.3"
        rx="0.961095"
        stroke="#9FB3C8"
        strokeWidth="0.64073"
      />
      <path
        d="M99.1322 90.4264V90.2407C99.1358 89.6036 99.1923 89.0958 99.3015 88.7172C99.4143 88.3386 99.5782 88.0328 99.7929 87.7998C100.008 87.5668 100.266 87.3556 100.568 87.1663C100.794 87.0207 100.996 86.8696 101.175 86.7131C101.353 86.5565 101.495 86.3836 101.6 86.1943C101.706 86.0013 101.759 85.7866 101.759 85.5499C101.759 85.2987 101.699 85.0785 101.579 84.8892C101.458 84.6999 101.296 84.5542 101.093 84.4523C100.892 84.3504 100.67 84.2994 100.426 84.2994C100.19 84.2994 99.9659 84.3522 99.7547 84.4578C99.5436 84.5597 99.3706 84.7126 99.2359 84.9165C99.1013 85.1167 99.0284 85.3661 99.0175 85.6646H96.7895C96.8077 84.9365 96.9825 84.3358 97.3138 83.8625C97.645 83.3856 98.0837 83.0307 98.6298 82.7977C99.1759 82.5611 99.7784 82.4427 100.437 82.4427C101.162 82.4427 101.803 82.5629 102.36 82.8032C102.917 83.0398 103.353 83.3838 103.67 83.8352C103.987 84.2867 104.145 84.8309 104.145 85.468C104.145 85.8939 104.074 86.2726 103.932 86.6038C103.794 86.9315 103.599 87.2227 103.348 87.4776C103.097 87.7288 102.8 87.9563 102.458 88.1602C102.17 88.3313 101.934 88.5097 101.748 88.6953C101.566 88.881 101.429 89.0958 101.338 89.3397C101.251 89.5836 101.205 89.884 101.202 90.2407V90.4264H99.1322ZM100.213 93.9213C99.8494 93.9213 99.5381 93.7939 99.2796 93.539C99.0248 93.2805 98.8992 92.9711 98.9028 92.6107C98.8992 92.2539 99.0248 91.9481 99.2796 91.6933C99.5381 91.4384 99.8494 91.311 100.213 91.311C100.559 91.311 100.863 91.4384 101.125 91.6933C101.387 91.9481 101.52 92.2539 101.524 92.6107C101.52 92.851 101.457 93.0712 101.333 93.2714C101.213 93.468 101.054 93.6264 100.858 93.7465C100.661 93.863 100.446 93.9213 100.213 93.9213Z"
        fill="#0F5291"
      />
      <rect
        x="94.9892"
        y="79.129"
        width="10.8926"
        height="17.3"
        rx="0.961095"
        stroke="#9FB3C8"
        strokeWidth="0.64073"
      />
      <path
        d="M111.788 90.4264V90.2407C111.791 89.6036 111.848 89.0958 111.957 88.7172C112.07 88.3386 112.234 88.0328 112.448 87.7998C112.663 87.5668 112.922 87.3556 113.224 87.1663C113.449 87.0207 113.652 86.8696 113.83 86.7131C114.008 86.5565 114.15 86.3836 114.256 86.1943C114.361 86.0013 114.414 85.7866 114.414 85.5499C114.414 85.2987 114.354 85.0785 114.234 84.8892C114.114 84.6999 113.952 84.5542 113.748 84.4523C113.548 84.3504 113.326 84.2994 113.082 84.2994C112.845 84.2994 112.621 84.3522 112.41 84.4578C112.199 84.5597 112.026 84.7126 111.891 84.9165C111.757 85.1167 111.684 85.3661 111.673 85.6646H109.445C109.463 84.9365 109.638 84.3358 109.969 83.8625C110.3 83.3856 110.739 83.0307 111.285 82.7977C111.831 82.5611 112.434 82.4427 113.093 82.4427C113.817 82.4427 114.458 82.5629 115.015 82.8032C115.572 83.0398 116.009 83.3838 116.325 83.8352C116.642 84.2867 116.801 84.8309 116.801 85.468C116.801 85.8939 116.73 86.2726 116.588 86.6038C116.449 86.9315 116.254 87.2227 116.003 87.4776C115.752 87.7288 115.455 87.9563 115.113 88.1602C114.826 88.3313 114.589 88.5097 114.403 88.6953C114.221 88.881 114.085 89.0958 113.994 89.3397C113.906 89.5836 113.861 89.884 113.857 90.2407V90.4264H111.788ZM112.869 93.9213C112.505 93.9213 112.194 93.7939 111.935 93.539C111.68 93.2805 111.555 92.9711 111.558 92.6107C111.555 92.2539 111.68 91.9481 111.935 91.6933C112.194 91.4384 112.505 91.311 112.869 91.311C113.215 91.311 113.519 91.4384 113.781 91.6933C114.043 91.9481 114.176 92.2539 114.179 92.6107C114.176 92.851 114.112 93.0712 113.988 93.2714C113.868 93.468 113.71 93.6264 113.513 93.7465C113.317 93.863 113.102 93.9213 112.869 93.9213Z"
        fill="#0F5291"
      />
      <rect
        x="107.804"
        y="79.129"
        width="10.8926"
        height="17.3"
        rx="0.961095"
        stroke="#9FB3C8"
        strokeWidth="0.64073"
      />
      <g filter="url(#filter3_dd_10_3317)">
        <circle
          cx="86.2587"
          cy="119.178"
          r="12.4945"
          fill="white"
          stroke="#D9E2EC"
          strokeWidth="1.92219"
        />
        <path
          d="M86.2574 114.694V123.664M81.7722 119.179H90.7426"
          stroke="#0F5291"
          strokeWidth="1.28148"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_10_3317"
          x="34.1639"
          y="40.3745"
          width="119.085"
          height="85.7673"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1.59426"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_10_3317"
          />
          <feOffset dy="3.18851" />
          <feGaussianBlur stdDeviation="2.39139" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10_3317" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2.39139"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_10_3317"
          />
          <feOffset dy="7.97129" />
          <feGaussianBlur stdDeviation="5.97846" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_10_3317"
            result="effect2_dropShadow_10_3317"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_10_3317"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dd_10_3317"
          x="26.9559"
          y="47.5815"
          width="119.085"
          height="85.7673"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1.59426"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_10_3317"
          />
          <feOffset dy="3.18851" />
          <feGaussianBlur stdDeviation="2.39139" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10_3317" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2.39139"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_10_3317"
          />
          <feOffset dy="7.97129" />
          <feGaussianBlur stdDeviation="5.97846" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_10_3317"
            result="effect2_dropShadow_10_3317"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_10_3317"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_dd_10_3317"
          x="19.747"
          y="54.7905"
          width="119.085"
          height="85.7673"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1.59426"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_10_3317"
          />
          <feOffset dy="3.18851" />
          <feGaussianBlur stdDeviation="2.39139" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10_3317" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2.39139"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_10_3317"
          />
          <feOffset dy="7.97129" />
          <feGaussianBlur stdDeviation="5.97846" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_10_3317"
            result="effect2_dropShadow_10_3317"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_10_3317"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_dd_10_3317"
          x="63.2374"
          y="104.129"
          width="46.0424"
          height="46.0423"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1.59426"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_10_3317"
          />
          <feOffset dy="3.18851" />
          <feGaussianBlur stdDeviation="2.39139" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10_3317" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2.39139"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_10_3317"
          />
          <feOffset dy="7.97129" />
          <feGaussianBlur stdDeviation="5.97846" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_10_3317"
            result="effect2_dropShadow_10_3317"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_10_3317"
            result="shape"
          />
        </filter>
      </defs>
    </>
  ),
});
