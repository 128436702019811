/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type ValidityCell_ClaimCodeFragment = { __typename: 'MultiClaimCodeDocument', id: string, expiry?: any | null };

export const ValidityCell_ClaimCodeFragmentDoc = gql`
    fragment ValidityCell_claimCode on MultiClaimCodeDocument {
  id
  expiry
}
    `;