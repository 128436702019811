import { gql } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { ClaimCodeTable_ClaimCodeFragment } from '../__generated__/ClaimCodeTable.graphql';

export function CodeCell({
  value,
}: PropsWithChildren<CellProps<ClaimCodeTable_ClaimCodeFragment, string>>) {
  return (
    <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" fontWeight="semibold">
      {value}
    </Box>
  );
}

CodeCell.graphql = {
  fragments: {
    CodeCell_claimCode: gql`
      fragment CodeCell_claimCode on MultiClaimCodeDocument {
        id
        code
      }
    `,
  },
};
