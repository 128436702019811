/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type MultiClaimSlotAvailableQueryVariables = Types.Exact<{
  input: Types.MultiClaimSlotAvailableInput;
}>;


export type MultiClaimSlotAvailableQuery = { __typename: 'Query', multiClaimSlotAvailable: boolean };

export type MultiClaimUpdateMutationVariables = Types.Exact<{
  input: Types.GenerateMultiClaimCodeInput;
}>;


export type MultiClaimUpdateMutation = { __typename: 'Mutation', generateMultiClaimCode: { __typename: 'MultiClaimCodeDocument', id: string, code: string, createdAt: any } };


export const MultiClaimSlotAvailableDocument = gql`
    query MultiClaimSlotAvailable($input: MultiClaimSlotAvailableInput!) {
  multiClaimSlotAvailable(input: $input)
}
    `;

/**
 * __useMultiClaimSlotAvailableQuery__
 *
 * To run a query within a React component, call `useMultiClaimSlotAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useMultiClaimSlotAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultiClaimSlotAvailableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMultiClaimSlotAvailableQuery(baseOptions: ApolloReactHooks.QueryHookOptions<MultiClaimSlotAvailableQuery, MultiClaimSlotAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MultiClaimSlotAvailableQuery, MultiClaimSlotAvailableQueryVariables>(MultiClaimSlotAvailableDocument, options);
      }
export function useMultiClaimSlotAvailableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MultiClaimSlotAvailableQuery, MultiClaimSlotAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MultiClaimSlotAvailableQuery, MultiClaimSlotAvailableQueryVariables>(MultiClaimSlotAvailableDocument, options);
        }
export type MultiClaimSlotAvailableQueryHookResult = ReturnType<typeof useMultiClaimSlotAvailableQuery>;
export type MultiClaimSlotAvailableLazyQueryHookResult = ReturnType<typeof useMultiClaimSlotAvailableLazyQuery>;
export type MultiClaimSlotAvailableQueryResult = ApolloReactCommon.QueryResult<MultiClaimSlotAvailableQuery, MultiClaimSlotAvailableQueryVariables>;
export const MultiClaimUpdateDocument = gql`
    mutation multiClaimUpdate($input: GenerateMultiClaimCodeInput!) {
  generateMultiClaimCode(input: $input) {
    id
    code
    createdAt
  }
}
    `;
export type MultiClaimUpdateMutationFn = ApolloReactCommon.MutationFunction<MultiClaimUpdateMutation, MultiClaimUpdateMutationVariables>;

/**
 * __useMultiClaimUpdateMutation__
 *
 * To run a mutation, you first call `useMultiClaimUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMultiClaimUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [multiClaimUpdateMutation, { data, loading, error }] = useMultiClaimUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMultiClaimUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MultiClaimUpdateMutation, MultiClaimUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<MultiClaimUpdateMutation, MultiClaimUpdateMutationVariables>(MultiClaimUpdateDocument, options);
      }
export type MultiClaimUpdateMutationHookResult = ReturnType<typeof useMultiClaimUpdateMutation>;
export type MultiClaimUpdateMutationResult = ApolloReactCommon.MutationResult<MultiClaimUpdateMutation>;
export type MultiClaimUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<MultiClaimUpdateMutation, MultiClaimUpdateMutationVariables>;