import { gql } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { ClaimCodeTable_ClaimCodeFragment } from '../__generated__/ClaimCodeTable.graphql';

interface LocationCellProps
  extends PropsWithChildren<CellProps<ClaimCodeTable_ClaimCodeFragment, string>> {
  sites: Array<{ id: string; name: string }>;
}
export function LocationCell({ value, sites }: LocationCellProps) {
  const locationLabel = sites.find((site) => site.id === value)?.name || 'Unknown';
  return (
    <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
      {locationLabel}
    </Box>
  );
}

LocationCell.graphql = {
  fragments: {
    LocationCell_claimCode: gql`
      fragment LocationCell_claimCode on MultiClaimCodeDocument {
        id
        site
      }
    `,
  },
};
