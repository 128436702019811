import { createIcon } from '@chakra-ui/react';

export const ImagePlaylistTypeIllustration = createIcon({
  displayName: 'SingleDisplayIllustration',
  viewBox: '0 0 173 173',
  defaultProps: {
    fill: 'none',
    width: '173px',
    height: '173px',
  },
  path: (
    <>
      <circle cx="75" cy="75" r="74" fill="#F0F4F8" stroke="#0F5291" strokeWidth="2" />
      <g filter="url(#filter0_dd_305_34491)">
        <path
          d="M31.6641 53.3351C31.6641 50.8805 33.6539 48.8906 36.1085 48.8906H113.886C116.341 48.8906 118.331 50.8805 118.331 53.3351V98.8906C118.331 101.345 116.341 103.335 113.886 103.335H36.1085C33.6539 103.335 31.6641 101.345 31.6641 98.8906V53.3351Z"
          fill="white"
        />
        <path
          d="M53.8863 103.335H97.7751V104.446C97.7751 105.673 96.7802 106.668 95.5529 106.668H56.1085C54.8812 106.668 53.8863 105.673 53.8863 104.446V103.335Z"
          fill="white"
        />
        <path
          d="M53.8863 102.224H52.7752H36.1085C34.2676 102.224 32.7752 100.732 32.7752 98.8906V53.3351C32.7752 51.4941 34.2676 50.0017 36.1085 50.0017H113.886C115.727 50.0017 117.22 51.4941 117.22 53.3351V98.8906C117.22 100.732 115.727 102.224 113.886 102.224H98.8862H97.7751H53.8863ZM54.9974 104.446H96.664C96.664 105.06 96.1666 105.557 95.5529 105.557H56.1085C55.4948 105.557 54.9974 105.06 54.9974 104.446Z"
          stroke="#0F5291"
          strokeWidth="2.22222"
        />
      </g>
      <path
        d="M84 69H66C65 69 64 70 64 71V85C64 86.1 64.9 87 66 87H84C85 87 86 86 86 85V71C86 70 85 69 84 69ZM84 84.92C83.98 84.95 83.94 84.98 83.92 85H66V71.08L66.08 71H83.91C83.94 71.02 83.97 71.06 83.99 71.08V84.92H84ZM74 81.51L71.5 78.5L68 83H82L77.5 77L74 81.51Z"
        fill="#0F5291"
      />
      <defs>
        <filter
          id="filter0_dd_305_34491"
          x="23.3701"
          y="47.5083"
          width="103.252"
          height="74.3658"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1.38233"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_305_34491"
          />
          <feOffset dy="2.76465" />
          <feGaussianBlur stdDeviation="2.07349" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_305_34491" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2.07349"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_305_34491"
          />
          <feOffset dy="6.91164" />
          <feGaussianBlur stdDeviation="5.18373" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_305_34491"
            result="effect2_dropShadow_305_34491"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_305_34491"
            result="shape"
          />
        </filter>
      </defs>
    </>
  ),
});
